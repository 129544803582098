import Grid from '@mui/material/Unstable_Grid2';
import AngieCategoryTable from "./AngieCategoryTable";
import SensitiveCategoryTable from "./SensitiveCategoryTable";

const PSCSetting = () => {
    
    return (
        <>
            <Grid xs={12}>
                <AngieCategoryTable />
            </Grid>
            <Grid xs={12}>
                <SensitiveCategoryTable />
            </Grid>
        </>
    )
}

export default PSCSetting;