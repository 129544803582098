import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import GetPersonInfo from '../ui/GetPersonInfo';
import classes from './PscMemberGrid.module.css';
import styled from '@emotion/styled';
import LoadingSpinner from '../ui/LoadingSpinner';

const MemberCard = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	height: '100%',
	color: theme.palette.text.secondary,
}));

const UserInfo = ({ user }) => (
	<Box className={classes.userinfo}>
		<img
			src={user.photo?.replace('=s96-c', '') || './assets/person-placeholder_0.png'}
			alt={user.name || 'PSC Member'}
			referrerPolicy='no-referrer'
		/>
		<Typography variant='h3'>{user.name || 'No Name'}</Typography>
		<Link href={'mailto:' + user.email} target='_blank'>
			{user.email || 'No Email'}
		</Link>
		<Typography>
			<b>Mobile:</b> {user.phone || 'No Phone'}
		</Typography>
	</Box>
);

const PscMemberGrid = (props) => {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const updatePscList = async () => {
			const promises = props.list.map(async (item) => {
				const personInfo = await GetPersonInfo(item.currentPerson.email, props.token);
				return {
					id: item.id,
					photo: personInfo.thumbnailPhotoUrl,
					name: personInfo.name.fullName,
					email: personInfo.primaryEmail,
					phone: personInfo.phones[0]?.type === 'mobile' ? personInfo.phones[0]['value'] : '',
				};
			});
			const tmplist = await Promise.all(promises);
			setRows(tmplist);
			setLoading(false);
		};
		updatePscList();
	}, [props.list, props.token]);

	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && (
				<Grid container spacing={2} justifyContent='center' alignItems='stretch' className={classes.usergriditem}>
					{rows.map((row, index) => {
						return (
							<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
								<MemberCard>
									<UserInfo user={row} />
								</MemberCard>
							</Grid>
						);
					})}
				</Grid>
			)}
		</>
	);
};

export default PscMemberGrid;
