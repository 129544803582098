import { Fragment, useContext, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

import { IoIosCloseCircleOutline } from "react-icons/io";
import UploadIcon from '../images/upload-icon.svg';
import TextEditor from "../textEditor/TextEditor";
import AlertContext from "../ui/AlertContext";
import isExternalLink from "../localFunctions/isExternalLink";
import { addDoc, collection } from "firebase/firestore";
import { db, storage } from "../../App";
import { getDownloadURL, ref, updateMetadata, uploadBytes } from 'firebase/storage';
import styled from '@emotion/styled';
import { GrResources } from "react-icons/gr";
import AttachmentPreview from "../ui/AttachmentPreview";

const AttachmentView = styled(Box)(({ theme }) => ({
	width: '200px',
	maxHeight: '180px',
	minHeight: "125px",
	height: '100%',
	overflow: 'hidden',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: '#d9d9d9',
	position: 'relative',
	"&:hover": {
		background: "#c1bfbf"
	},
    sm: {
        width: '48%'
    }
}));

const CreateResourceForm = (props) => {
    const alertCtx = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [resourceContent, setResourceContent] = useState({});
    const [visible, setVisible] = useState(false);
    const [isMain, setIsMain] = useState(false);
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [elink, setElink] = useState('');
    const [elinkError, setElinkError] = useState(false);

    const [imagePreview, setImagePreview] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [iconPreview, setIconPreview] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(null);
    useEffect(() => {
        if (selectedImage) {
            if (selectedImage.file.type.startsWith('image/')) {
                setImagePreview(URL.createObjectURL(selectedImage.file))
            } else {
                const extension = selectedImage.file.name.split('.').pop().toLowerCase();
                setImagePreview(extension);
            }
        } else {
            setImagePreview(null);
        }
    },[selectedImage])
    useEffect(() => {
        if (selectedIcon) {
            setIconPreview(URL.createObjectURL(selectedIcon.file))
        } else {
            setIconPreview(null);
        }
    },[selectedIcon])
    const alert = (type, msg, duration = 5000) => {
        alertCtx.setSeverity(type);
        alertCtx.setTitle(msg);
        alertCtx.setActive(true);
        alertCtx.setTimer(duration);
    }
    const formatForm = () => {
        setTitle('');
        setTitleError(false);
        setElink('');
        setElinkError(false);
        setResourceContent({});
        setSelectedImage(null);
        setSelectedIcon(null);
    }
    const handleSubmit = async () => {
        if (titleError || elinkError) return;
        if (!resourceContent.valid){
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Resource Content must be filled');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            return;
        }
        const data = {
            Name: title,
            Details: resourceContent.text,
            Link: elink,
            Visible: visible,
            IsMain: isMain
        }

        if (selectedImage) {
            const downloadURL = await handleUploadProcess(selectedImage);
            if (downloadURL) {
                data.attachment = downloadURL;
            }
        }

        if (selectedIcon) {
            const iconUrl = await handleUploadProcess(selectedIcon);
            if (iconUrl) {
                data.Icon = iconUrl;
            }
        }

        const resourceRef = collection(db, 'PSC', 'Resources', 'Resources');
        try {
            const docRef = await addDoc(resourceRef, data);
            props.handleAddItem({
                id: docRef.id,
                ...data
            });
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Your resource was submitted successfully.');
            alertCtx.setActive(true);
			alertCtx.setTimer(5000);
            formatForm();
            setIsLoading(false);
        } catch (e) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Your resource was <strong>not</strong> submitted. Try again.');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            setIsLoading(false);
        }
    }    

    // handle attachment
    const handleFileUpload = (e, t) => {
        const file = e.target.files[0];
        if (file) {
            // Check if the file type is an image
            if (t === 'icon' && !file.type.startsWith('image/')) {
                alert('error', 'Please select an image file.');
                return;
            }

            // Check if the file size is more than 50MB (50 * 1024 * 1024 bytes)
            if (file.size > 50 * 1024 * 1024) {
                alert('error', 'File size must not exceed 50MB.');
                return;
            }

            // Create the unique file name
            const uniqueFileName = `${Date.now()}_${file.name}`;

            // Set the selected image with its unique name
            if (t === 'icon') {
                setSelectedIcon({
                    file: file,
                    name: uniqueFileName
                });
            } else {
                setSelectedImage({
                    file: file,
                    name: uniqueFileName
                });
            }
            console.log(uniqueFileName)
        }
    }
    
    const handleUploadClick = () => {
        document.getElementById('resource-attachment-submit-input').click();
    }
    const handleIconUploadClick = () => {
        document.getElementById('resource-icon-submit-input').click();
    }
    const handleRemoveFile = () => {
        setSelectedImage(null);
    }
    const handleRemoveIcon = () => {
        setSelectedIcon(null);
    }
    const handleUploadProcess = async (uploadImage) => {
        const storageRef = ref(storage, `PSC/Resources/${uploadImage.name}`);
        const snapshot = await uploadBytes(storageRef, uploadImage.file);
        const metadata = {
            customMetadata: {
                userName: props.name
            }
        }

        try{
            await updateMetadata(storageRef, metadata);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        } catch (error) {
            alert('error', error.message);
            return null;
        }
    }

    const handleChange = (e, type) => {
        switch(type) {
            case 'Name':
                setTitle(e.target.value);
                if (!e.target.value) setTitleError(true);
                else setTitleError(false);
                break;
            case 'Elink':
                setElink(e.target.value);
                if (e.target.value && !isExternalLink(e.target.value)) setElinkError(true);
                else setElinkError(false);
                break;
            case 'Visible':
                setVisible(e.target.value);
                break;
            case 'IsMain':
                setIsMain(e.target.value);
                break;
            default:
                break;
        }
    }
    const handleResourceChange = (e) => {
        setResourceContent(e);
    }
    return (
        <Fragment>
            <Box display='flex' justifyContent='space-between' sx={{flexWrap: { xs: 'wrap', sm: 'nowrap' }}}>
                <Typography variant="h4">Create Resource</Typography>
                <FormControl sx={{ width: { xs: '100%', sm: '25%'}, maxWidth: '400px' }} margin='dense'>
                    <InputLabel htmlFor="visible">Is Main Resource?</InputLabel>
                    <Select
                        color='primary'
                        id="isMain"
                        label="Is Main Resource?"
                        value={isMain}
                        onChange={(e) => handleChange(e, 'IsMain')}
                        size="small"
                        fullWidth
                    >
                        <MenuItem value={true}>
                            True
                        </MenuItem>
                        <MenuItem value={false}>
                            False
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box 
                display='flex' 
                justifyContent='space-between' 
                gap='5px' 
                sx={{marginBottom: '5px', marginTop: '10px', flexWrap: { xs: 'wrap', sm: 'nowrap' }}}
            >
                <Box width='100%'>
                    <TextField
                        label='Resource Name'
                        variant='outlined'
                        value={title}
                        onChange={(e) => handleChange(e, 'Name')}
                        size="small"
                        error={titleError}
                        helperText={titleError? 'Empty resource name': ''}
                        fullWidth
                    />
                </Box>
                <Box width='48%'>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel htmlFor="visible">Visible</InputLabel>
                        <Select
                            color='primary'
                            id="visible"
                            label="Visible"
                            value={visible}
                            onChange={(e) => handleChange(e, 'Visible')}
                            size="small"
                            fullWidth
                        >
                            <MenuItem value={true}>
                                True
                            </MenuItem>
                            <MenuItem value={false}>
                                False
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box width='48%'>
                    <TextField
                        label='External Link'
                        variant='outlined'
                        value={elink}
                        onChange={(e) => handleChange(e, 'Elink')}
                        size="small"
                        error={elinkError}
                        helperText={elinkError? "Missing a 'https' or 'http' prefix.": ''}
                        fullWidth
                    />
                </Box>
            </Box>
            <Box sx={{width: '100%'}}>
                <TextEditor
                    initialContent={resourceContent.text}
                    onChange={handleResourceChange}
                />
            </Box>
            <Box 
                sx={{
                    marginTop: '15px', 
                    display: 'flex', 
                    gap: '20px',
                    flexWrap: { xs: 'wrap', sm: 'nowrap' }
                }}
            >
                <Box>
                    <Typography variant="p" style={{ marginBottom: '5px', marginTop: '10px' }}>Attachment</Typography>
                    <AttachmentView>
                        {selectedImage ? (
                            <AttachmentPreview 
                                preview={imagePreview} 
                                handleUploadClick={handleUploadClick} 
                                handleRemoveFile={handleRemoveFile}
                                isPreview={true}
                            />
                        ) : (
                            <img 
                                src={UploadIcon} 
                                alt="Upload" 
                                style={{ width: '100px', margin: 'auto', marginTop: '9px', cursor: 'pointer' }} 
                                onClick={() => handleUploadClick()}
                            />
                        )}
                    </AttachmentView>
                </Box>
                <Box>
                    <Typography variant="p" style={{ marginBottom: '5px', marginTop: '10px' }}>Resource Icon</Typography>
                    <AttachmentView>
                        {selectedIcon ? (
                            <>
                                <img src={iconPreview} alt="Angie Preview" style={{ width: '100%', height: 'auto', filter: 'brightness(0.9)' }} onClick={() => handleIconUploadClick()}/>
                                <Typography variant="h6" sx={{
                                        position: 'absolute',
                                        color: 'white',
                                        fontFamily: 'unset',
                                        fontWeight: '800',
                                        opacity: '0.5',
                                        letterSpacing: '3px',
                                        bottom: 0
                                }}>Preview</Typography>
                                <Box sx={{position: 'absolute', right: '5px', top: '5px', 'cursor': 'pointer', fontSize: '1.5rem', "&:hover": {color: 'red'}}}>
                                    <IoIosCloseCircleOutline  onClick={() => handleRemoveIcon()}></IoIosCloseCircleOutline>
                                </Box>
                            </>
                        ) : (
                            <Stack
                                m={2}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Button
                                    onClick={() => handleIconUploadClick()}
                                    variant='contained'
                                    size='large'
                                    color='button'
                                    sx={{
                                        "&:hover" : {
                                            background: '#4d2b93'
                                        },
                                        height: '120px',
                                        width: '150px',
                                        borderRadius: '8px',
                                        margin: 'auto',
                                        padding: '16px',
                                        paddingBottom: '32px',
                                        background: '#5e35b1'
                                    }}
                                >
                                    <Stack>
                                        <Container>
                                            <GrResources size={80} />
                                        </Container>
                                    </Stack>
                                    <Typography variant="h6" sx={{
                                        position: 'absolute',
                                        color: 'white',
                                        fontFamily: 'unset',
                                        fontWeight: '800',
                                        opacity: '0.5',
                                        letterSpacing: '3px',
                                        bottom: 0
                                    }}>Default</Typography>
                                </Button>
                                <Box sx={{position: 'absolute', right: '5px', top: '5px', 'cursor': 'pointer', fontSize: '1.5rem', "&:hover": {color: 'red'}}}>
                                    <IoIosCloseCircleOutline  onClick={() => handleRemoveIcon()}></IoIosCloseCircleOutline>
                                </Box>
                            </Stack>
                        )}
                    </AttachmentView>
                </Box>
            </Box>
            <input id="resource-attachment-submit-input" style={{display: 'none'}} type="file" onChange={(e) => handleFileUpload(e, 'attachment')} />
            <input id="resource-icon-submit-input" style={{display: 'none'}} type="file" onChange={(e) => handleFileUpload(e, 'icon')}  accept="image/*" />
            <Box display='flex' justifyContent='right'>
                <Button
                    variant='contained'
                    color='button'
                    onClick={handleSubmit}
                    disabled={isLoading? true: false}
                >
                    Submit
                </Button>
            </Box>
        </Fragment>)
}

export default CreateResourceForm;