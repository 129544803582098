import { useEffect, useState, useContext } from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import TextEditor from '../textEditor/TextEditor';

import PageCards from '../ui/PageCards';
import { Subscribe, Unsubscribe } from '../ui/GetSubscribe';
import AlertContext from '../ui/AlertContext';
import { TbEdit } from 'react-icons/tb';
import { MdDeleteOutline } from 'react-icons/md';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../App';

const CommensalityTable = (props) => {
	const alertCtx = useContext(AlertContext);
	const [rows, setRows] = useState([]);
	const [deleteRowId, setDeleteRowId] = useState(null);
	const [modalObj, setModalObj] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [loadingStates, setLoadingStates] = useState([]);

	useEffect(() => {
		setRows(props.list);
		const tmpArr = props.list.map(() => false);
		setLoadingStates(tmpArr);
	}, [props.list]);

	const onSubscribe = async (item, index) => {
		const newLoadingStates = JSON.parse(JSON.stringify(loadingStates));
		newLoadingStates[index] = true;
		setLoadingStates(newLoadingStates);
		try {
			const response = await Subscribe(item);
			if (response.data.code === 200) {
				alertCtx.setSeverity('success');
				alertCtx.setTitle('Successfully subscribed');
				alertCtx.setActive(true);
				alertCtx.setTimer(5000);
				const tmpRows = rows.map((one) => {
					if (one.id === item.id) {
						return {
							...item,
							SubscribeUsers: [...item.SubscribeUsers, props.email],
						};
					} else return one;
				});
				setRows(tmpRows);
			} else {
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Subscribe failed');
				alertCtx.setActive(true);
				alertCtx.setTimer(5000);
			}
		} catch (error) {
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Subscribe failed');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
		} finally {
			newLoadingStates[index] = false;
			setLoadingStates(newLoadingStates);
		}
	};

	const onUnsubscribe = async (item, index) => {
		const newLoadingStates = JSON.parse(JSON.stringify(loadingStates));
		newLoadingStates[index] = true;
		setLoadingStates(newLoadingStates);
		try {
			const response = await Unsubscribe(item);
			if (response.data.code === 200) {
				const tmpRows = rows.map((one) => {
					if (one.id === item.id) {
						const tempSubscribeUsers = item.SubscribeUsers.filter((item) => item !== props.email);
						return {
							...item,
							SubscribeUsers: tempSubscribeUsers,
						};
					} else return one;
				});
				setRows(tmpRows);
				alertCtx.setSeverity('success');
				alertCtx.setTitle('Successfully unsubscribed');
				alertCtx.setActive(true);
				alertCtx.setTimer(5000);
			} else {
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Unsubscribe failed');
				alertCtx.setActive(true);
				alertCtx.setTimer(5000);
			}
		} catch (error) {
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Unsubscribe failed');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
		} finally {
			newLoadingStates[index] = false;
			setLoadingStates(newLoadingStates);
		}
	};

	const handleEditClick = (row) => {
		setModalObj(row);
		setModalOpen(true);
	};

	const handleChange = (e, type) => {
		setModalObj({
			...modalObj,
			[type]: e.target.value,
		});
	};

	const handleDeleteClick = (row) => {
		setDeleteRowId(row.id);
		setConfirmOpen(true);
	};

	const handleResourceChange = (e) => {
		setModalObj({
			...modalObj,
			Blurb: e.text,
		});
	};

	const handleSaveResource = () => {
		const idx = modalObj.id;
		if (!idx) {
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Select the commensality to update, again');
			alertCtx.setTimer(5000);
			alertCtx.setActive(true);
			return;
		}
		const commensalityRef = doc(db, 'PSC', 'Commensality', 'Commensality', idx);
		const tempObj = JSON.parse(JSON.stringify(modalObj));
		delete tempObj['id'];
		updateDoc(commensalityRef, tempObj)
			.then(() => {
				setModalOpen(false);
				updateResourceList(modalObj, 'update');
				alertCtx.setSeverity('success');
				alertCtx.setTitle('Selected commensality successfully updated!');
				alertCtx.setActive(true);
				alertCtx.setTimer(5000);
			})
			.catch((error) => {
				setModalOpen(false);
				alertCtx.setSeverity('error');
				alertCtx.setTitle(error.message);
				alertCtx.setActive(true);
				alertCtx.setTimer(5000);
			});
	};

	const updateResourceList = (one, type = 'update') => {
		if (type === 'update') {
			const newRows = rows.map((item) => {
				if (item.id === one.id) return one;
				else return item;
			});
			setRows(newRows);
		} else {
			const newRows = rows.filter((item) => item.id !== one);
			setRows(newRows);
		}
	};

	const handleDeleteAction = async () => {
		if (!deleteRowId) {
			handleClose();
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Select delete button, again');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
			return;
		}
		const commensalityRef = doc(db, 'PSC', 'Commensality', 'Commensality', deleteRowId);
		try {
			await deleteDoc(commensalityRef);
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Commensality group successfully deleted!');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
			updateResourceList(deleteRowId, 'delete');
			handleClose();
		} catch (error) {
			alertCtx.setSeverity('error');
			alertCtx.setTitle(error.message);
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
			handleClose();
		}
	};

	const handleClose = () => {
		setModalOpen(false);
		setConfirmOpen(false);
	};

	return (
		<Box width='100%'>
			<Grid container spacing={1}>
				{rows.length === 0 ? (
					<Typography textAlign={'center'} width={'100%'} mt={3}>
						No Content Posted
					</Typography>
				) : null}

				{rows.map((item, index) => (
					<Grid spacing={2} justifyContent='center' xs={3} key={index}>
						<PageCards>
							<Typography variant='h4' textAlign='center'>
								{item.GoogleGroupName}
							</Typography>
							<div className='no-margin-p-left' dangerouslySetInnerHTML={{ __html: item.Blurb }}></div>
							<p>
								Link:{' '}
								<a href={item.Link} rel='noreferrer' target='_blank'>
									{item.Link}
								</a>
							</p>
							<p>Email: {item.Email}</p>
							{props.pscMember && <p>Visible: {item.Visible ? 'True' : 'False'}</p>}
							<Box display='flex' justifyContent={props.pscMember ? 'space-between' : 'center'}>
								{loadingStates[index] ? (
									<Button variant='contained' size='small' color='cancel' disabled={true}>
										<CircularProgress color='inherit' size={22} />
									</Button>
								) : item.SubscribeUsers && item.SubscribeUsers.length && item.SubscribeUsers.includes(props.email) ? (
									<Button variant='contained' size='small' color='cancel' onClick={() => onUnsubscribe(item, index)}>
										Unsubscribe
									</Button>
								) : (
									<Button variant='contained' size='small' onClick={() => onSubscribe(item, index)}>
										Subscribe
									</Button>
								)}
								{props.pscMember && (
									<Box>
										<IconButton onClick={() => handleEditClick(item)}>
											<TbEdit />
										</IconButton>
										<IconButton onClick={() => handleDeleteClick(item)}>
											<MdDeleteOutline />
										</IconButton>
									</Box>
								)}
							</Box>
						</PageCards>
					</Grid>
				))}
			</Grid>

			<Dialog open={modalOpen} onClose={handleClose} maxWidth='xl'>
				<Box sx={{ padding: '5px' }}>
					<DialogTitle>Edit Commensality</DialogTitle>
					<DialogContent>
						<Box width='100%' mt={1} display='flex' justifyContent='space-between' gap='5px'>
							<TextField
								fullWidth
								size='small'
								margin='dense'
								value={modalObj.GoogleGroupName}
								onChange={(e) => handleChange(e, 'GoogleGroupName')}
								label='Google Group Name'
								hiddenLabel
								variant='outlined'
								color='secondary'
							/>
							<TextField
								fullWidth
								size='small'
								margin='dense'
								value={modalObj.Link}
								onChange={(e) => handleChange(e, 'Link')}
								label='Link'
								hiddenLabel
								variant='outlined'
								color='secondary'
							/>
						</Box>
						<Box width='100%' display='flex' justifyContent='space-between' gap='5px'>
							<TextField
								fullWidth
								size='small'
								margin='dense'
								value={modalObj.Email}
								onChange={(e) => handleChange(e, 'Email')}
								label='Email'
								hiddenLabel
								variant='outlined'
								color='secondary'
							/>
							<FormControl fullWidth margin='dense'>
								<InputLabel id={`Visible`}>Visible</InputLabel>
								<Select
									id='CustomVisible'
									margin='dense'
									color='secondary'
									labelId='Visible'
									label='Visible'
									size='small'
									value={modalObj.Visible ? true : false}
									onChange={(e) => handleChange(e, 'Visible')}
								>
									<MenuItem value={true}>True</MenuItem>
									<MenuItem value={false}>False</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Box>
							<TextEditor initialContent={modalObj.Blurb} onChange={handleResourceChange} />
						</Box>
					</DialogContent>
					<DialogActions>
						<Button variant='contained' onClick={handleClose} color='cancel'>
							Cancel
						</Button>
						<Button variant='contained' onClick={handleSaveResource}>
							Save
						</Button>
					</DialogActions>
				</Box>
			</Dialog>

			<Dialog open={confirmOpen} onClose={handleClose} maxWidth='xl'>
				<DialogTitle id='alert-dialog-title'>Delete Group</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						<Alert severity='warning'>You must remove this group in workspace first</Alert>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleDeleteAction} autoFocus color='error'>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default CommensalityTable;
