import { useEffect, useMemo, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Container from "@mui/material/Container";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import DeleteConfirmDialog from './DeleteConfirmDialog';

import CloseSvg from '../images/close.svg';
import UploadIcon from '../images/upload-icon.svg';
import { GrResources } from "react-icons/gr";
import { TbEdit } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import PageCards from '../../components/ui/PageCards';
import TextEditor from '../textEditor/TextEditor';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from "../../App";
import { getDownloadURL, ref, updateMetadata, uploadBytes } from 'firebase/storage';
import AlertContext from '../ui/AlertContext';
import isExternalLink from '../localFunctions/isExternalLink';
import styled from '@emotion/styled';
import AttachmentPreview from '../ui/AttachmentPreview';
import { GoDownload } from 'react-icons/go';

const AttachmentView = styled(Box)(({ theme }) => ({
	width: '200px',
	maxHeight: '180px',
	minHeight: "125px",
	height: '100%',
	overflow: 'hidden',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: '#d9d9d9',
	position: 'relative',
	"&:hover": {
		background: "#c1bfbf"
	}
}));
const ResourceCard = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    height: '100%',
    color: theme.palette.text.secondary,
    border: '1px solid gray',
    borderRadius: '5px'
}));

const ResourceTile = (props) => {
    const stripHtml = (html) => {
        const tempororyDiv = document.createElement('div');
        tempororyDiv.innerHTML = html;

        const text =  tempororyDiv.textContent || tempororyDiv.innerText || "";

        return text.slice(0, 200);
    }

    const cleanDetail = stripHtml(props.row.Details);
    
    return (
        <Grid item 
            xs={12}
            sm={6}
            md={4}
            lg={3}
        >
            <ResourceCard >
                <Link
                    href={props.row.Link}
                    target='_blank'
                    m={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {props.row.Icon ? (
                        <Box
                            sx={{
                                height: '120px',
                                width: '100%',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <img src={props.row.Icon} 
                                alt={props.row.Name}
                                style={{ 
                                    width: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                    borderRadius: '5px'
                                }} 
                            />
                        </Box>
                    ) : (
                        <Button
                            variant='contained'
                            size='large'
                            color='button'
                            sx={{
                                "&:hover" : {
                                    background: '#4d2b93'
                                },
                                height: '120px',
                                width: '150px',
                                borderRadius: '8px',
                                margin: 'auto',
                                padding: '16px',
                                background: '#5e35b1'
                            }}
                        >
                            <Stack>
                                <Container>
                                    <GrResources size={80} />
                                </Container>
                            </Stack>
                        </Button>
                    )}
                </Link>
                <Box 
                    mx={2} 
                    height="calc(100% - 120px - 2rem)"
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-between'
                >
                    <Box>
                        <Link 
                            href={props.row.Link}
                            target='_blank'
                            sx={{
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            <Typography 
                                variant='h5'
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    paddingBottom: '5px'
                                }}
                            >{props.row.Name}</Typography>
                        </Link>
                        <Box
                            sx={{
                                textAlign: 'left',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: '4',
                                WebkitBoxOrient: 'vertical',
                                wordWrap: 'break-word'
                            }}
                        >
                            {cleanDetail}
                        </Box>
                    </Box>
                    <Box display='flex' justifyContent='center' gap="5px">
                        {props.row.attachment && 
                            <Link href={props.row.attachment} target="_blank" download>
                                <IconButton>
                                    <GoDownload></GoDownload>
                                </IconButton>
                            </Link>
                        }
                        {props.pscMember && (
                            <>
                                <IconButton onClick={() => props.handleEditClick(props.row)}><TbEdit></TbEdit></IconButton>
                                <IconButton onClick={() => props.handleDeleteClick(props.row)}><MdDeleteOutline></MdDeleteOutline></IconButton>
                            </>
                        )}
                    </Box>
                </Box>
            </ResourceCard>
        </Grid>
    )
}

const ResourcesTable = (props) => {
    const alertCtx = useContext(AlertContext);
    const [rows, setRows] = useState([]);
    const [mainRows, setMainRows] = useState([]);
    const [modalObj, setModalObj] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [elinkError, setElinkError] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [deleteRowId, setDeleteRowId] = useState(null);

    const [imagePreview, setImagePreview] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageOpen, setImageOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');
    const [iconPreview, setIconPreview] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(null);

    useEffect(() => {
        setRows(props.list.filter( item => !item.IsMain));
        setMainRows(props.list.filter( item => item.IsMain));
    },[props.list]);

    useEffect(() => {
        if (selectedImage) {
            if (selectedImage.file.type.startsWith('image/')) {
                setImagePreview(URL.createObjectURL(selectedImage.file))
            } else {
                const extension = selectedImage.file.name.split('.').pop().toLowerCase();
                setImagePreview(extension);
            }
        } else {
            setImagePreview(null);
        }
    },[selectedImage]);

    useEffect(() => {
        if (selectedIcon) {
            setIconPreview(URL.createObjectURL(selectedIcon.file))
        } else {
            setIconPreview(null);
        }
    },[selectedIcon]);

    const alert = (type, msg, duration = 5000) => {
        alertCtx.setSeverity(type);
        alertCtx.setTitle(msg);
        alertCtx.setActive(true);
        alertCtx.setTimer(duration);
    }

    const handleChange = (e, type) => {
        if (type==="Name") {
            if(e.target.value === "") setTitleError(true);
            else setTitleError(false);
        } else if(type==="Link") {
            if (e.target.value!=="" && !isExternalLink(e.target.value)) setElinkError(true);
            else setElinkError(false);
        } 
        setModalObj({
            ...modalObj,
            [type]: e.target.value
        })
    }

    const handleEditClick = (row) => {
        setModalObj(row);
        setModalOpen(true);
    }
    const handleDeleteClick = (row) => {
        setDeleteRowId(row.id);
        setConfirmOpen(true);
    }
    const handleDeleteAction = async () => {
        if (!deleteRowId) {
            handleClose();
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Select resource to delete, again');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            return;
        }
        const resourceRef = doc(db, 'PSC', 'Resources', 'Resources',deleteRowId)
        try {
            await deleteDoc(resourceRef);
            alertCtx.setSeverity('success');
            alertCtx.setTitle('Resouce successfully deleted!');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            props.updateResourceList(deleteRowId, 'delete');
            handleClose();
        } catch(error) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle(error.message);
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            handleClose();
        }
    }
    const handleResourceChange = (e) => {
        setModalObj({
            ...modalObj,
            Details: e.text
        })
    }
    const handleSaveResource = async () => {
        const idx = modalObj.id;
        if ( !idx ) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Select the resource to update, again');
            alertCtx.setTimer(5000);
            alertCtx.setActive(true);
            return;
        }
        const resourceRef = doc(db, 'PSC', 'Resources', 'Resources', idx);
        const tempObj = JSON.parse(JSON.stringify(modalObj));
        delete tempObj['id'];

        if (selectedImage) {
            const downloadURL = await handleUploadProcess(selectedImage);
            if (downloadURL === null) {
                return ;
            }
            tempObj.attachment = downloadURL;
        }
        if (selectedIcon) {
            const iconURL = await handleUploadProcess(selectedIcon);
            if (iconURL === null) {
                return ;
            }
            tempObj.Icon = iconURL;
        }
        updateDoc(resourceRef, tempObj)
            .then(() => {
                setModalOpen(false);
                props.updateResourceList({attachment:tempObj.attachment, Icon: tempObj.Icon, ...modalObj}, 'update');
                alertCtx.setSeverity('success');
                alertCtx.setTitle("Selected resource successfully updated!");
                alertCtx.setActive(true);
                alertCtx.setTimer(5000);
            })
            .catch((error) => {
                setModalOpen(false);
                alertCtx.setSeverity('error');
                alertCtx.setTitle(error.message);
                alertCtx.setActive(true);
                alertCtx.setTimer(5000);
            })
    }
    const handleClose = () => {
        setModalOpen(false);
        setConfirmOpen(false);
		setSelectedImage('');
		setImageOpen(false);
    }
	const handleImageClick = (url) => {
        setSelectedImageUrl(url);
        setImageOpen(true);
    }
    // handle attachment
    const handleFileUpload = (e, t) => {
        const file = e.target.files[0];
        if (file) {
            // Check if the file type is an image
            if (t === 'icon' && !file.type.startsWith('image/')) {
                alert('error', 'Please select an image file.');
                return;
            }

            // Check if the file size is more than 50MB (50 * 1024 * 1024 bytes)
            if (file.size > 50 * 1024 * 1024) {
                alert('error', 'File size must not exceed 50MB.');
                return;
            }

            // Create the unique file name
            const uniqueFileName = `${Date.now()}_${file.name}`;

            // Set the selected image with its unique name
            if (t === 'icon') {
                setSelectedIcon({
                    file: file,
                    name: uniqueFileName
                });
            } else {
                setSelectedImage({
                    file: file,
                    name: uniqueFileName
                });
            }
			console.log(uniqueFileName)
        }
    }
    const handleUploadClick = () => {
        document.getElementById('resource-attachment-input').click();
    }
    const handleIconUploadClick = () => {
        document.getElementById('resource-icon-input').click();
    }
    const handleRemoveFile = () => {
        setSelectedImage(null);
    }
    const handleUploadProcess = async (img) => {
        const storageRef = ref(storage, `PSC/Resources/${img.name}`);
        const snapshot = await uploadBytes(storageRef, img.file);
        const metadata = {
            customMetadata: {
                userName: props.name
            }
        }

        try{
            await updateMetadata(storageRef, metadata);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        } catch (error) {
            alert('error', error.message);
            return null;
        }
    }
    const  isImageFile = (url) => {
        const baseUrl = url.split('?')[0];
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.svg'];
        const extension = (baseUrl.match(/\.[0-9a-z]+$/i) || [""])[0].toLowerCase();
        return imageExtensions.includes(extension);
    }
    
    const columnsPscResouce = useMemo(() => {
        return [
            {
                accessorKey: 'id',
                header: 'ID'
            },
            {
                id: 'Name',
                accessorFn: (row) => (row.Name ? `${row.Name}`: ''),
                header: 'Name',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({ row }) => (
                    <Box display='flex' justifyContent='center'>
                        <Stack direction='row' spacing={1}>
                            <p>{row.original.Name}</p>
                        </Stack>
                    </Box>
                )
            },
            {
                id: 'Link',
                accessorFn: (row) => (row.Link? row.Link: ''),
                header: 'External Link',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({row}) => (
                    <Box display='flex' justifyContent='center'>
                        <Stack direction='row' spacing={1}>
                            {
                                row.original.Link && (
                                    <a href={row.original.Link} target="_blank" rel="noopener noreferrer">{row.original.Link}</a>
                                )
                            }
                        </Stack>
                    </Box>
                )
            },
            {
                id: 'Visible',
                accessorFn: (row) => (row.Visible? row.Visible: ''),
                header: 'Visible',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({row}) => (
                    <Box display='flex' justifyContent='center'>
                        <Stack direction='row' spacing={1}>
                            <p>{row.original.Visible? 'True': 'False'}</p>
                        </Stack>
                    </Box>
                )
            },
            {
                id: 'Attachment',
                accessorFn: (row) => (row.attachment ? `${row.attachment}`: ''),
                header: 'Attachment',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({ row }) => (
                    row.original.attachment ? (
                        <Box display='flex' justifyContent='center'>
                            <Link href={row.original.attachment} target="_blank" download>
                                <IconButton>
                                    <GoDownload></GoDownload>
                                </IconButton>
                            </Link>
                        </Box>
                    ) : (
                        ''
                    )
                )
            },
            {
                id: 'action',
                header: 'Action',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({row}) => (
                    <Box display='flex' justifyContent='center' gap="5px">
                        <IconButton onClick={() => handleEditClick(row.original)}><TbEdit></TbEdit></IconButton>
                        <IconButton onClick={() => handleDeleteClick(row.original)}><MdDeleteOutline></MdDeleteOutline></IconButton>
                    </Box>
                )
            }
        ];
    },[])
    const columnsResource = useMemo(() => {
        return [
            {
                accessorKey: 'id',
                header: 'ID'
            },
            {
                id: 'Name',
                accessorFn: (row) => (row.Name ? `${row.Name}`: ''),
                header: 'Name',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({ row }) => (
                    <Box display='flex' justifyContent='center'>
                        <Stack direction='row' spacing={1}>
                            <p>{row.original.Name}</p>
                        </Stack>
                    </Box>
                )
            },
            {
                id: 'Link',
                accessorFn: (row) => (row.Link? row.Link: ''),
                header: 'External Link',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({row}) => (
                    <Box display='flex' justifyContent='center'>
                        <Stack direction='row' spacing={1}>
                            {
                                row.original.Link && (
                                    <a href={row.original.Link} target="_blank" rel="noopener noreferrer">{row.original.Link}</a>
                                )
                            }
                        </Stack>
                    </Box>
                )
            },
            {
                id: 'Attachment',
                accessorFn: (row) => (row.attachment ? `${row.attachment}`: ''),
                header: 'Attachment',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({ row }) => (
                    row.original.attachment && (
                        <Box display='flex' justifyContent='center'>
                            <Link href={row.original.attachment} target="_blank" download>
                                <IconButton>
                                    <GoDownload></GoDownload>
                                </IconButton>
                            </Link>
                        </Box>
                    )
                )
            },
        ];
    },[])
    const table = useMaterialReactTable({
        columns: props.pscMember? columnsPscResouce : columnsResource,
        data: rows,
        muiTablePaperProps: {
            elevation: 0,
            sx: {
                '& tr:nth-of-type(even)': {
                    backgroundColor: '#f5f5f5'
                }
            }
        },
        displayColumnDefOptions: {
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    align: 'center'
                },
                size: 120
            }
        },
        initialState: {
            density: 'compact',
            expanded: false,
            pagination: { pageIndex: 0, pageSize: 15 },
            sorting: [{ id: 'Name', desc: false}],
            columnVisibility: {
                id: false
            }
        },
        renderDetailPanel: ({row}) => (
            <Box display='flex' justifyContent='center' gap='10px'>
                <Stack direction={'row'} spacing={1}>
                    <div className="no-margin-p" dangerouslySetInnerHTML={{ __html: row.original.Details }}></div>
                </Stack>
                {row.original.attachment && isImageFile(row.original.attachment) && <AttachmentView>
                    <img 
                        src={row.original.attachment} 
                        alt="Review attachment" 
                        style={{ width: '100%', height: 'auto', filter: 'brightness(0.9)', cursor: 'pointer' }}
                        onClick={() => handleImageClick(row.original.attachment)}
                    />
                </AttachmentView>}
            </Box>
        )
    });
    return (
        <>
            <PageCards>
                <Box xs={{width: "100%"}}>
                    <Typography variant='h4' color="primary" textAlign="center" padding={2}>Main Resources</Typography>
                    <Grid 
                        container 
                        spacing={2}
                        sx={{
                            width: '90%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            justifyContent: 'center',
                            alignItems: "stretch"
                        }}
                    >
                        { mainRows.map( (row, index) => {
                            return <ResourceTile 
                            row={row} 
                            key={index}
                            handleEditClick={handleEditClick}
                            handleDeleteClick={handleDeleteClick}
                            pscMember={props.pscMember}
                            ></ResourceTile>
                        })}
                    </Grid>
                </Box>
            </PageCards>
            <PageCards>
                <Box xs={{width: "100%"}}>
                    <MaterialReactTable
                        table={table}
                    ></MaterialReactTable>
                </Box>
            </PageCards>
            <Box xs={{width: "100%"}}>
                <Dialog open={modalOpen} onClose={handleClose} maxWidth="xl">
                    <Box sx={{ padding: '5px' }}>
                        <DialogTitle>
                            <Box display='flex' justifyContent='space-between' sx={{flexWrap: { xs: 'wrap', sm: 'nowrap' }}}>
                                <Typography variant="h5">Edit Blurb of Resource</Typography>
                                <FormControl sx={{ width: { xs: '100%', sm: '25%'}, maxWidth: '400px' }} margin='dense'>
                                    <InputLabel htmlFor="visible">Is Main Resource?</InputLabel>
                                    <Select
                                        color='primary'
                                        id="isMain"
                                        label="Is Main Resource?"
                                        value={modalObj.IsMain ? true : false}
                                        onChange={(e) => handleChange(e, 'IsMain')}
                                        size="small"
                                        fullWidth
                                    >
                                        <MenuItem value={true}>
                                            True
                                        </MenuItem>
                                        <MenuItem value={false}>
                                            False
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>                            
                        </DialogTitle>
                        <DialogContent>
                            <Box 
                                display='flex' 
                                justifyContent='space-between' 
                                gap='5px' 
                                sx={{marginBottom: '5px', marginTop: '10px', flexWrap: { xs: 'wrap', sm: 'nowrap' }}}
                            >
                                <Box width='100%'>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        margin='dense'
                                        value={modalObj.Name}
                                        onChange={(e) => handleChange(e, 'Name')}
                                        label="Resource Name"
                                        error={titleError}
                                        helperText={titleError? 'Empty resource name': ''}
                                        hiddenLabel
                                        variant='outlined'
                                        color='secondary'
                                    />
                                </Box>
                                <Box width='48%'>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        margin='dense'
                                        value={modalObj.Link}
                                        onChange={(e) => handleChange(e, 'Link')}
                                        label="External Link"
                                        error={elinkError}
                                        helperText={elinkError? "Missing a 'https' or 'http' prefix.": ''}
                                        hiddenLabel
                                        variant='outlined'
                                        color='secondary'
                                    />
                                </Box>
                                <Box width='48%'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel id={`Visible`}>Visible</InputLabel>
                                        <Select
                                            id='CustomVisible'
                                            margin="dense"
                                            color='secondary'
                                            labelId='Visible'
                                            label='Visible'
                                            size="small"
                                            value={modalObj.Visible? true: false}
                                            onChange={(e) => handleChange(e, 'Visible')}
                                        >
                                            <MenuItem value={true}>True</MenuItem>
                                            <MenuItem value={false}>False</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box>
                                <TextEditor
                                    initialContent={modalObj.Details}
                                    onChange={handleResourceChange}
                                />
                            </Box>
                            <Box
                                sx={{
                                    marginTop: '15px', 
                                    display: 'flex', 
                                    gap: '20px',
                                    flexWrap: { xs: 'wrap', sm: 'nowrap' }
                                }}
                            >
                                <Box>
                                    <Typography variant="p" style={{ marginBottom: '5px', marginTop: '10px' }}>Attachment</Typography>
                                    <AttachmentView>
                                        {selectedImage ? (
                                            <AttachmentPreview 
                                                preview={imagePreview} 
                                                handleUploadClick={handleUploadClick} 
                                                handleRemoveFile={handleRemoveFile}
                                                isPreview={true}
                                            />
                                        ) : (
                                            ( modalObj.attachment ) ? (
                                                <AttachmentPreview 
                                                    preview={modalObj.attachment} 
                                                    handleUploadClick={handleUploadClick} 
                                                    handleRemoveFile={() => {}}
                                                    isPreview={false}
                                                />
                                            ) : (
                                                <img 
                                                    src={UploadIcon} 
                                                    alt="Upload" 
                                                    style={{ width: '100px', margin: 'auto', marginTop: '9px', cursor: 'pointer' }} 
                                                    onClick={() => handleUploadClick()}
                                                />
                                            )
                                        )}
                                    </AttachmentView>
                                </Box>
                                <Box>
                                    <Typography variant="p" style={{ marginBottom: '5px', marginTop: '10px' }}>Resource Icon</Typography>
                                    <AttachmentView>
                                        {selectedIcon ? (
                                            <>
                                                <img src={iconPreview} alt="Resource Icon Preview" style={{ width: '100%', height: 'auto', filter: 'brightness(0.9)' }} onClick={() => handleIconUploadClick()}/>
                                                <Typography variant="h6" sx={{
                                                    position: 'absolute',
                                                    color: 'white',
                                                    fontFamily: 'unset',
                                                    fontWeight: '800',
                                                    opacity: '0.5',
                                                    letterSpacing: '3px'
                                                }}>Preview</Typography>
                                            </>
                                        ) : (
                                            modalObj.Icon ? (
                                                <img src={modalObj.Icon} alt="Resource Icon" style={{ width: '100%', height: 'auto', filter: 'brightness(0.9)' }} onClick={() => handleIconUploadClick()}/>
                                            ) : (
                                                <Stack
                                                    m={2}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Button
                                                        onClick={() => handleIconUploadClick()}
                                                        variant='contained'
                                                        size='large'
                                                        color='button'
                                                        sx={{
                                                            "&:hover" : {
                                                                background: '#4d2b93'
                                                            },
                                                            height: '120px',
                                                            width: '150px',
                                                            borderRadius: '8px',
                                                            margin: 'auto',
                                                            padding: '16px',
                                                            background: '#5e35b1'
                                                        }}
                                                    >
                                                        <Stack>
                                                            <Container>
                                                                <GrResources size={80} />
                                                            </Container>
                                                        </Stack>
                                                        <Typography variant="h6" sx={{
                                                            position: 'absolute',
                                                            color: 'white',
                                                            fontFamily: 'unset',
                                                            fontWeight: '800',
                                                            opacity: '0.5',
                                                            letterSpacing: '3px'
                                                        }}>Default</Typography>
                                                    </Button>
                                                </Stack>
                                            )
                                        )}
                                    </AttachmentView>
                                </Box>
                            </Box>
                            <input id="resource-attachment-input" style={{display: 'none'}} type="file" onChange={(e) => handleFileUpload(e, 'attachment')} />
                            <input id="resource-icon-input" style={{display: 'none'}} type="file" onChange={(e) => handleFileUpload(e, 'icon')}  accept="image/*" />
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' onClick={handleClose} color='cancel'>
                                Cancel
                            </Button>
                            <Button
                                variant='contained'
                                onClick={handleSaveResource}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>
                <Dialog
                    open={imageOpen}
                    onClose={handleClose}
                    maxWidth="xl"
                >
                    <DialogContent style={{ overflow: 'hidden', padding: "0px", position: 'relative' }}>
                        <img src={CloseSvg} alt="close" onClick={handleClose} style={{ position: 'absolute', width: '35px', height: '35px', top: "0px", right: '0px', cursor: 'pointer' }}/>
                        <img src={selectedImageUrl} alt={"loading..."} style={{ height: '100vh'}}/>
                    </DialogContent>
                </Dialog>
                <DeleteConfirmDialog
                    open={confirmOpen}
                    handleClose={handleClose}
                    handleDeleteAction={handleDeleteAction}
                />
            </Box>
        </>
    )
}

export default ResourcesTable;
