import { gapi } from "gapi-script"
import { API_KEY } from "../auth/SignIn";

const GetPersonInfo = (emailAddress, token) => {
    return new Promise((resolve, reject) => {
        const initClient = async () => {
            gapi.client.setToken({ access_token: token });
            try {
                await gapi.client
                    .request({
                        method: 'GET',
                        path: `https://admin.googleapis.com/admin/directory/v1/users/${emailAddress}?projection=basic&viewType=domain_public&key=${API_KEY}`,
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then((response) => {
                        resolve(response.result);
                    })
            } catch(error) {
                reject(error);
            }
        }
        if (!emailAddress) {
            resolve('');
        } else {
            gapi.load('client', initClient);
        }
    })
}

export default GetPersonInfo