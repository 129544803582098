const getDateTimeFromTimestamp = (num) => {
    let date = new Date();
    if (num?.seconds) {
        date = new Date(num.seconds*1000);
    } else {
        if (typeof num === 'number') date = new Date(num*1000)
        else date = new Date(num);
    }
    const formattedDate = date.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    return `${formattedDate}`;
}

export default getDateTimeFromTimestamp;