import React, { useEffect, useRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

const options = {
	defaultStyle: 'font-family: Arial; font-size: 16px;', // Set default font and size here
	buttonList: [
		['undo', 'redo'],
		['formatBlock', 'fontSize', 'fontColor', 'hiliteColor'],
		['blockquote', 'bold', 'underline', 'italic', 'align', 'list'],
		['outdent', 'indent'],
		['removeFormat'],
		['link', 'image'],
		['table'],
		['fullScreen', 'showBlocks', 'codeView'],
	],
};

/**
 * TextEditor Component
 * @param {object} props - Component props.
 * @param {string} [props.initialContent] - Optional initial content to load into the editor.
 * @param {boolean} [props.disabled] - Whether the editor should be disabled.
 * @param {function} [props.onChange] - Optional callback function to handle content changes.
 * @returns JSX Element
 */
export default function TextEditor({ initialContent = '', disabled = false, onChange }) {
	const editorRef = useRef(null);
	const [content, setContent] = useState(initialContent);

	// Apply image alignment whenever content changes
	useEffect(() => {
		const modifiedContent = applyImageAlignment(content);
		setContent(modifiedContent);
	}, []);

	// Function to handle content change
	const handleEditorContentChange = (newContent) => {
		const updatedContent = applyImageAlignment(newContent);
		setContent(updatedContent);

		if (onChange) {
			const isValid =
				updatedContent.trim() !== '<p></p>' && updatedContent.trim() !== '<p><br></p>' && updatedContent.trim() !== '';
			onChange({ text: updatedContent, valid: isValid }); // Notify parent if onChange is provided
		}
	};

	// Function to apply image alignment (center, right, left alignment)
	const applyImageAlignment = (content) => {
		// Handle center alignment
		let updatedContent = content.replace(
			/<img([^>]+)data-align="center"([^>]*)style="([^"]*)"/g,
			(match, beforeAlign, afterAlign, styleContent) => {
				// Remove conflicting styles
				const cleanStyle = styleContent
					.replace(/float:\s*(left|right);?/g, '')
					.replace(/margin-(left|right):\s*15px;?/g, '')
					.replace(/margin:\s*auto;?/g, '')
					.trim();

				// Ensure center-alignment styles are applied
				const updatedStyle = `${cleanStyle}; display: block; margin: auto;`.trim();

				return `<img${beforeAlign}data-align="center"${afterAlign}style="${updatedStyle}"`;
			}
		);

		// Handle right alignment
		updatedContent = updatedContent.replace(
			/<img([^>]+)data-align="right"([^>]*)style="([^"]*)"/g,
			(match, beforeAlign, afterAlign, styleContent) => {
				// Remove conflicting styles
				const cleanStyle = styleContent
					.replace(/display:\s*block;?/g, '')
					.replace(/margin:\s*auto;?/g, '')
					.replace(/float:\s*left;?/g, '')
					.replace(/margin-right:\s*15px;?/g, '')
					.trim();

				// Ensure right-alignment styles are applied
				const updatedStyle = `${cleanStyle}; float: right; margin-left: 15px; margin-bottom: 15px;`.trim();

				return `<img${beforeAlign}data-align="right"${afterAlign}style="${updatedStyle}"`;
			}
		);

		// Handle left alignment
		updatedContent = updatedContent.replace(
			/<img([^>]+)data-align="left"([^>]*)style="([^"]*)"/g,
			(match, beforeAlign, afterAlign, styleContent) => {
				// Remove conflicting styles
				const cleanStyle = styleContent
					.replace(/display:\s*block;?/g, '')
					.replace(/margin:\s*auto;?/g, '')
					.replace(/float:\s*right;?/g, '')
					.replace(/margin-left:\s*15px;?/g, '')
					.trim();

				// Ensure left-alignment styles are applied
				const updatedStyle = `${cleanStyle}; float: left; margin-right: 15px; margin-bottom: 15px;`.trim();

				return `<img${beforeAlign}data-align="left"${afterAlign}style="${updatedStyle}"`;
			}
		);

		return updatedContent;
	};

	return (
		<SunEditor
			getSunEditorInstance={(editor) => (editorRef.current = editor)} // Store editor instance in ref
			setOptions={options}
			onChange={handleEditorContentChange}
			setContents={content}
			disable={disabled}
			height='700px'
		/>
	);
}
