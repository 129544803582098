import React, { Fragment, useState, useContext } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import AuthContext from "../../components/auth/authContext";
import PageCards from "../../components/ui/PageCards";

import { SiGoogledomains } from "react-icons/si";
import { GrResources } from "react-icons/gr";
import { TbSitemap } from "react-icons/tb";
import { GoIssueTracks } from "react-icons/go";
import { BsBoundingBox } from "react-icons/bs";
import { MdOutlineEmojiEvents } from "react-icons/md";

import Main from "./Main";
import Resources from "./Resources";
import AngieList from "./AngieList";
import SensitiveIssues from "./SensitiveIssues";
import Commensality from "./Commensality";
import EptWideEvents from "./EptWideEvents";

const PSC = () => {
    const authCtx = useContext(AuthContext);
	const [pageLink, setPageLink] = useState('main');
	const icons = [
		{ name: 'MAIN', link: 'main', icon: <SiGoogledomains size={80} />, color:"#5e35b1", hoverColor: "#4d2b93" },
		{ name: 'RESOURCES', link: 'resources', icon: <GrResources size={80} />, color:"#5e35b1", hoverColor: "#4d2b93" },
		{ name: "ANGIE'S LIST", link: 'angieslist', icon: <TbSitemap size={80} />, color:"#5e35b1", hoverColor: "#4d2b93" },
		{ name: 'SENSITIVE ISSUES', link: 'sensitiveissues', icon: <GoIssueTracks size={80} />, color:"#5e35b1", hoverColor: "#4d2b93" },
		{ name: 'COMMENSALITY', link: 'commensality', icon: <BsBoundingBox size={80} />, color:"#5e35b1", hoverColor: "#4d2b93" },
		{ name: 'EPT-Wide Events', link: 'eptwideevents', icon: <MdOutlineEmojiEvents size={80} />, color:"#5e35b1", hoverColor: "#4d2b93" }
	];
	const handlePageChange = (link) => {
		setPageLink(link);
	}
    return (
        <Fragment>
			{/* if logged in */}
			{authCtx.isLoggedIn && (
				<Grid container spacing={1}>
					<Grid xs={12}>
						<PageCards>
							<Container>
								{/* portal icons */}
								<Grid container spacing={2} columns={4} justifyContent='center'>
									{
										icons.map((icon, index) => (
											<Stack
												m={2}
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center'
												}}
												key={index}
											>
												<Button
													onClick={() => handlePageChange(icon.link)}
													variant='contained'
													size='large'
													color='button'
													sx={{
														"&:hover" : {
															background: icon.hoverColor? icon.hoverColor: ''
														},
														height: '120px',
														width: '150px',
														borderRadius: '8px',
														margin: 'auto',
														padding: '16px',
														background: pageLink === icon.link ? '#3a1f71' : (icon.color? icon.color: '')
													}}
												>
													<Stack>
														<Container>{icon.icon}</Container>
														<Typography variant='h7' fontWeight={400} textAlign='center' color='white' width={133}>
															{icon.name}
														</Typography>
													</Stack>
												</Button>
											</Stack>
										))
									}
								</Grid>
							</Container>
                        </PageCards>
                    </Grid>
					{ pageLink === 'main' && (<Main />)}
					{ pageLink === 'resources' && (<Resources />)}
					{ pageLink === 'angieslist' && (<AngieList />)}
					{ pageLink === 'sensitiveissues' && (<SensitiveIssues />)}
					{ pageLink === 'commensality' && (<Commensality />)}
					{ pageLink === 'eptwideevents' && (<EptWideEvents />)}
                </Grid>
            )}
        </Fragment>
    );
}

export default PSC;