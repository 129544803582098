import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Button, Box, Menu, MenuItem, ListItemIcon, Divider, Stack } from '@mui/material';

import CustomClaimsContext from '../auth/CustomClaimsContext';
import { icons } from '../../pages/Home';

export default function NavigationMenu() {
	const claimsCtx = useContext(CustomClaimsContext);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	if (!claimsCtx?.claimsLoaded) return;

	return (
		<Fragment>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
				<Button
					variant='contained'
					color='button'
					onClick={handleClick}
					sx={{ ml: 2 }}
					aria-controls={open ? 'account-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={open ? 'true' : undefined}
				>
					MENU
				</Button>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 28,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{icons.map((icon, index) => {
					if (
						!icon.admin &&
						(!icon.memberType || claimsCtx.claims.memberType === icon.memberType) &&
						(icon.isEPT === undefined || claimsCtx.claims.isEPT === icon.isEPT)
					)
						return (
							<MenuItem component={Link} to={icon.link} key={index}>
								<Stack direction='row'>
									<ListItemIcon>{icon.iconMenu}</ListItemIcon>
									{icon.name}
								</Stack>
							</MenuItem>
						);
				})}
				{claimsCtx?.claims?.admin && <Divider sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} />}
				{claimsCtx?.claims?.admin &&
					icons.map((icon, index) => {
						return icon.admin ? (
							<MenuItem component={Link} to={icon.link} key={index}>
								<Stack direction='row'>
									<ListItemIcon>{icon.iconMenu}</ListItemIcon>
									{icon.name}
								</Stack>
							</MenuItem>
						) : null;
					})}
			</Menu>
		</Fragment>
	);
}
