import { Fragment, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextEditor from "../textEditor/TextEditor";
import AlertContext from "../ui/AlertContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../App";
import generateTimeOptions from "../localFunctions/generateTimeOptions";

const CreateEventForm = (props) => {
    const alertCtx = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState('');
    const [curDate, setCurDate] = useState(null);
    const [resourceContent, setResourceContent] = useState({});
    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('00:00');
    const [visible, setVisible] = useState(false);
    const timeOptions = generateTimeOptions();
    const formatForm = () => {
        setTitle('');
        setLocation('');
        setCurDate(null);
        setResourceContent({});
    }
    const handleSubmit = async () => {
        if (!title){
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Event title must be filled');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            return;
        }
        if (!curDate) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Event date must be selected');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            return;
        }
        const data = {
            Title: title,
            Details: resourceContent.text,
            Date: curDate,
            StartTime: startTime,
            EndTime: endTime,
            Location: location,
            Visible: visible,
        }
        setIsLoading(true);
        const eventRef = collection(db, 'PSC', 'Events', 'Events');
        try {
            const docRef = await addDoc(eventRef, data);
            data.Date = {
                seconds: Math.floor(data.Date.getTime()/1000),
                nanoseconds: data.Date.getTime() * 1000
            }
            props.handleAddItem({
                id: docRef.id,
                ...data
            });
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Event was submitted successfully.');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
            formatForm();
            setIsLoading(false);
        } catch (e) {
            alertCtx.setSeverity('error');
			alertCtx.setTitle('Event was <strong>not</strong> submitted. Try again.');
			alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            setIsLoading(false);
        }
    }
    const handleChange = (e, type) => {
        switch(type) {
            case 'Title':
                setTitle(e);
                break;
            case 'Location':
                setLocation(e);
                break;
            case 'Date':
                setCurDate(e);
                break;
            case 'StartTime':
                setStartTime(e);
                break;
            case 'EndTime':
                setEndTime(e);
                break;
            case 'Visible':
                setVisible(e);
                break;
            default:
                break;
        }
    }
    const handleResourceChange = (e) => {
        setResourceContent(e);
    }
    return (
        <Fragment>
            <Typography variant="h4">Create New Event</Typography>
            <Box display='flex' justifyContent='space-between' gap='5px'>
                <Box width="100%">
                    <TextField
                        id="sensitive_issue_title"
                        label="Title"
                        value={title}
                        onChange={(e) => handleChange(e.target.value, 'Title')}
                        size="small"
                        InputProps={{ sx: {marginBottom: '5px'}}}
                        fullWidth
                    />
                </Box>
                <Box width="60%" display='flex' justifyContent='space-between' gap='5px'>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel htmlFor="visible">Visible</InputLabel>
                        <Select
                            color='primary'
                            id="visible"
                            label="Visible"
                            value={visible}
                            onChange={(e) => handleChange(e.target.value, 'Visible')}
                            size="small"
                            fullWidth
                        >
                            <MenuItem value={true}>
                                True
                            </MenuItem>
                            <MenuItem value={false}>
                                False
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box display='flex' justifyContent='space-between' gap='5px' mt={1}>
                <Box width='100%' display='flex' justifyContent='space-between' gap='5px'>
                    <Box width="100%">
                        <TextField
                            label="Location"
                            value={location}
                            onChange={(e) => handleChange(e.target.value, 'Location')}
                            size="small"
                            InputProps={{ sx: {marginBottom: '5px'}}}
                            fullWidth
                        />
                    </Box>
                    <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label='Event Date'
                                value={curDate}
                                onChange={(newValue) => {
                                    const value = newValue === null? null : newValue.$d;
                                    handleChange(value, 'Date')
                                }}
                                slotProps={{ textField: {size: 'small'}}}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>
                <Box width='60%' display='flex' justifyContent='space-between' gap='5px'>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel htmlFor="start_time">Start Time</InputLabel>
                        <Select
                            color='primary'
                            id="start_time"
                            label="Start Time"
                            value={startTime}
                            onChange={(e) => handleChange(e.target.value, 'StartTime')}
                            size="small"
                            fullWidth
                        >
                            {
                                timeOptions.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel htmlFor="end_time">End Time</InputLabel>
                        <Select
                            color='primary'
                            id="end_time"
                            label="End Time"
                            value={endTime}
                            onChange={(e) => handleChange(e.target.value, 'EndTime')}
                            size="small"
                            fullWidth
                        >
                            {
                                timeOptions.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box sx={{width: '100%'}} marginTop='5px'>
                <TextEditor
                    initialContent={resourceContent.text}
                    onChange={handleResourceChange}
                />
            </Box>
            <Box display='flex' justifyContent='right'>
                <Button
                    variant='contained'
                    color='button'
                    onClick={handleSubmit}
                    disabled={isLoading? true: false}
                >
                    Submit
                </Button>
            </Box>
        </Fragment>
    )
}

export default CreateEventForm;