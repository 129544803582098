import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 *
 * @param {*} token - the accessToken for the user
 * @returns - the photoURL of the email address provided
 */
const Subscribe = async (item) => {
    return new Promise((resolve, reject) => {
        const functions = getFunctions();
        try {
            const subscribeFunction = httpsCallable(functions, 'subscribeFunction');
            subscribeFunction(item).then((result) => {
                resolve(result);
            })
        } catch(error) {
            reject(error);
        }
    })
}

const Unsubscribe = async(item) => {
    return new Promise((resolve, reject) => {
        const functions = getFunctions();
        try { 
            const unSubscribeFunction = httpsCallable(functions, 'unSubscribeFunction');
            unSubscribeFunction(item).then((result) => {
                resolve(result);
            })
        } catch(error) {
            reject(error);
        }
    })
}

export {
    Subscribe,
    Unsubscribe
}