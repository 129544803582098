import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MdDeleteOutline } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../App";

import PageCards from '../../components/ui/PageCards';
import CheckToken from '../../components/auth/CheckToken';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import { useContext, useEffect, useState } from "react";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import AlertContext from "../ui/AlertContext";

function Row(props) {
	const {
		item,
		index,
		handleEditClick,
		handleDeleteClick,
	} = props;

	if (item) {
		return (
			<TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: index % 2 !== 0 ? '#f9f9f9' : '' }}>
				<TableCell align='center'>
					<Typography>{index + 1}</Typography>
				</TableCell>
				<TableCell>
					<Typography>{item}</Typography>
				</TableCell>
				<TableCell align='center'>
					<Box display='flex' justifyContent='center' gap="5px">
						<IconButton onClick={() => handleEditClick(index)}><TbEdit></TbEdit></IconButton>
						<IconButton onClick={() => handleDeleteClick(index)}><MdDeleteOutline></MdDeleteOutline></IconButton>
					</Box>
				</TableCell>
			</TableRow>
		);
	}
}
const AngieCategoryTable = () => {
	const alertCtx = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [modalObj, setModalObj] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [editLoading, setEditLoading] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
	const [deleteRowId, setDeleteRowId] = useState(null);

    useEffect(() => {
        const getCategories = async () => {
            setIsLoading(true);
            const categoryRef = doc(db, 'settings', 'PSCCategories');
            try{
                const temp_cat = (await getDoc(categoryRef)).data().AngiesList;
                setCategories(temp_cat);
            } catch(error) {
                console.log(error);
            }
            setIsLoading(false);
        }
        getCategories();
    },[]);

    const alert = (type, msg) => {
        alertCtx.setSeverity(type);
        alertCtx.setTitle(msg);
        alertCtx.setActive(true);
        alertCtx.setTimer(5000);
    }

    const handleAddCategory = () => {
        setModalObj({ind: categories.length, name: ''});
        setModalOpen(true);
    }

    const handleEdit = (ind) => {
        setModalObj({ind, name: categories[ind]});
        setModalOpen(true);
    }

    const handleDelete = (ind) => {
        setDeleteRowId(ind);
        setConfirmOpen(true);
    }

    const handleClose = () => {
        setModalOpen(false);
        setModalObj({});
        setConfirmOpen(false);
        setDeleteRowId(null);
    }

    const handleSaveCategory = async () => {
        const updatedCategories = [...categories];
        updatedCategories[modalObj.ind] = modalObj.name;

        setEditLoading(true);
        try {
            const categoryRef = doc(db, 'settings', 'PSCCategories');
            await updateDoc(categoryRef, { AngiesList: updatedCategories });
			alert('success', 'Selected row successfully updated!');
            setCategories(updatedCategories);
            setEditLoading(false);
            handleClose();
        } catch (error) {
			alert(error, error.message);
            setEditLoading(false);
        }
    }

    const handleDeleteAction = async () => {
        if (deleteRowId !== null) {
            const updatedCategories = [...categories];
            updatedCategories.splice(deleteRowId, 1);

            try {
                const categoryRef = doc(db, 'settings', 'PSCCategories');
                await updateDoc(categoryRef, { AngiesList: updatedCategories });
                alert('success', 'Selected row successfully deleted!');
                setCategories(updatedCategories);
                handleClose();
            } catch (error) {
                alert(error, error.message);
            }
        }
    }
    return (
        <>
            <Grid xs={12}>
                <PageCards>
                    <Box display='flex'>
                        <Typography variant="h5" color="primary" textAlign="center" width="100%">
                            PSC Angie Categories
                        </Typography>
                        
                        <Button
                            variant='contained'
                            onClick={handleAddCategory}
                        >
                            Add
                        </Button>
                    </Box>
                    {
                        isLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <LoadingSpinner />
                            </Box>
                        ) : (
                            <CheckToken>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center">Category</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { categories.map((category, index) => (
                                            <Row key={index} index={index} item={category} handleDeleteClick={handleDelete} handleEditClick={handleEdit} ></Row>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CheckToken>
                        )
                    }
                </PageCards>
            </Grid>
            <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg">
                <Box sx={{ padding: '5px' }}>
                    <DialogTitle>Edit Angie Category</DialogTitle>
                    <DialogContent>
                        <Box>
                            <TextField
                                fullWidth
                                size='small'
                                margin='dense'
                                value={modalObj.name}
                                label="Name"
                                hiddenLabel
                                variant="outlined"
                                color='secondary'
                                onChange={(e) => setModalObj({...modalObj, name: e.target.value}) }
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleClose} color="cancel">
                            Cancel
                        </Button>
                        {
                            editLoading
                            ? (
                                <Button variant="contained" size="small" color="cancel" disabled={true}>
                                    <CircularProgress color="inherit" size={22}/>
                                </Button>
                            ) : (
                                <Button
                                    variant='contained'
                                    onClick={handleSaveCategory}
                                >
                                    Save
                                </Button>
                            )
                        }
                    </DialogActions>
                </Box>
            </Dialog>
            <DeleteConfirmDialog
                open={confirmOpen}
                handleClose={handleClose}
                handleDeleteAction={handleDeleteAction}
            />
        </>
    )
}

export default AngieCategoryTable;