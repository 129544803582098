import React, { useContext, useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, getDocs } from 'firebase/firestore';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import PageCards from '../components/ui/PageCards';
import AuthContext from '../components/auth/authContext';
import AlertContext from '../components/ui/AlertContext';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';
import DirectoryList from '../components/directoryInfo/DirectoryList';
import DirectoryForm from '../components/directoryInfo/DirectoryForm';
import GetDirectory from '../components/directoryInfo/GetDirectory';
import { db } from '../App';
import CheckToken from '../components/auth/CheckToken';

function Directory() {
	const authCtx = useContext(AuthContext);
	const alertCtx = useContext(AlertContext);
	const claimsCtx = useContext(CustomClaimsContext);
	const [selectedMember, setSelectedMember] = useState();
	const [memberInfo, setMemberInfo] = useState();
	const [newInfo, setNewInfo] = useState(false);
	const [directoryLoading, setDirectoryLoading] = useState(false);
	const [memberLoading, setMemberLoading] = useState(false);
	const [list, setList] = useState(null);
	const [orderBy, setOrderBy] = useState('GIVEN_NAME');
	const [sortOrder, setSortOrder] = useState('ASCENDING');
	const [orderedList, setOrderedList] = useState([]);
	const [memberType, setMemberType] = useState('ALL');
	const [selection, setSelection] = useState('Not Suspended/Deleted');
	const [noDeletedUsers, setNoDeletedUsers] = useState(false);

	//handles member selection from DirectoryList
	const handleMemberSelection = (email) => {
		if (selectedMember === email) {
			// return;
		} else {
			setSelectedMember(email);
			setMemberLoading(true);

			window.scrollTo(0, 0);
		}
	};

	//clears the selected member
	const handleClearSelectedMember = () => {
		setSelectedMember();
		window.scrollTo(0, 0);
	};

	//getMemberInfos
	useEffect(() => {
		//if not a deleted user, then do this
		if (selection !== 'Deleted') {
			const functions = getFunctions();
			const getMemberInfoV2 = httpsCallable(functions, 'getMemberInfoV2');
			getMemberInfoV2({ member: selectedMember, admin: claimsCtx?.claims?.admin }).then((result) => {
				if (result.data.code === 200) {
					setMemberInfo(result.data.data);
					setMemberLoading(false);
				}
			});
		}

		//else, if a deleted user, do this
		else if (selection === 'Deleted') {
			if (list === 'No Deleted Users') {
				// console.log('No deleted users.');
			} else {
				list.map((person) => {
					if (person.email === selectedMember) {
						setMemberInfo({ adminResponse: { ...person } });
						setMemberLoading(false);
					}
				});
			}
		}
	}, [authCtx.email, claimsCtx?.claims?.admin, newInfo, selectedMember, selection, list]);

	//handles update from DirectoryForm
	const handleUpdate = () => {
		setNewInfo(true);
		setTimeout(() => {
			setNewInfo(false);
		}, 3000);
	};

	//handles order by change
	const handleOrderByChange = (change) => {
		setDirectoryLoading(true);
		setOrderedList([]);

		switch (change) {
			case 'GIVEN_NAME':
				setOrderBy('GIVEN_NAME');
				return;
			case 'FAMILY_NAME':
				setOrderBy('FAMILY_NAME');
				return;
			case 'EMAIL':
				setOrderBy('EMAIL');
				return;
			default:
				setOrderBy('GIVEN_Name');
				return;
		}
	};

	// handles order by change
	const handleSortOrderChange = (change) => {
		setDirectoryLoading(true);
		setOrderedList([]);

		switch (change) {
			case 'ASCENDING':
				setSortOrder('ASCENDING');
				return;
			case 'DESCENDING':
				setSortOrder('DESCENDING');
				return;
			default:
				setSortOrder('ASCENDING');
				return;
		}
	};

	// handles member type change
	const handleMemberTypeChange = (change) => {
		setDirectoryLoading(true);
		setOrderedList([]);

		switch (change) {
			case 'ALL':
				setMemberType('ALL');
				return;
			case 'APP':
				setMemberType('APP');
				return;
			case 'OFFICE':
				setMemberType('OFFICE');
				return;
			case 'PHYSICIAN':
				setMemberType('PHYSICIAN');
				return;
			case 'UNCLASSIFIED':
				setMemberType('UNCLASSIFIED');
				return;
			default:
				setMemberType('ALL');
				return;
		}
	};

	//order by, sorts, and filters the list
	useEffect(() => {
		if (list && list.title && list.title === 'Error') {
			alertCtx.setMessage(`<strong>There was an error. Logout and log back in. If the error continues to occur, contact IT and provide this error
			message: ${list.message}.</strong>`);
			alertCtx.setSeverity('error');
			alertCtx.setActive(true);
			// setError(list.message);
		} else if (list === 'No Deleted Users') {
			setNoDeletedUsers(true);
			setOrderedList([]);
		} else if (list) {
			let orderByVariable;
			if (orderBy === 'GIVEN_NAME') {
				orderByVariable = 'name';
			} else if (orderBy === 'FAMILY_NAME') {
				orderByVariable = 'familyName';
			} else if (orderBy === 'EMAIL') {
				orderByVariable = 'email';
			}

			let orderingList;
			if (sortOrder === 'ASCENDING') {
				orderingList = list.sort((a, b) =>
					a[orderByVariable].toLowerCase() > b[orderByVariable].toLowerCase()
						? 1
						: b[orderByVariable].toLowerCase() > a[orderByVariable].toLowerCase()
						? -1
						: 0
				);
			} else if (sortOrder === 'DESCENDING') {
				orderingList = list.sort((a, b) =>
					a[orderByVariable].toLowerCase() > b[orderByVariable].toLowerCase()
						? -1
						: b[orderByVariable].toLowerCase() > a[orderByVariable].toLowerCase()
						? 1
						: 0
				);
			}

			let results;
			if (memberType === 'ALL') {
				results = orderingList;
			} else if (
				memberType === 'APP' ||
				memberType === 'APP - Tier 1' ||
				memberType === 'APP - Tier 2' ||
				memberType === 'APP - Tier 3' ||
				memberType === 'APP - Tier 4'
			) {
				results = orderingList.filter((member) => {
					if (member.memberType !== null && member.memberType !== undefined) {
						return member.memberType.toLowerCase().includes('APP'.toLowerCase());
					}
				});
			} else if (memberType === 'OFFICE') {
				results = orderingList.filter((member) => {
					if (member.memberType !== null && member.memberType !== undefined) {
						return member.memberType.toLowerCase().includes('OFFICE'.toLowerCase());
					}
				});
			} else if (memberType === 'PHYSICIAN') {
				results = orderingList.filter((member) => {
					if (member.memberType !== null && member.memberType !== undefined) {
						return member.memberType.toLowerCase().includes('PHYSICIAN'.toLowerCase());
					}
				});
			} else if (memberType === 'UNCLASSIFIED') {
				results = orderingList.filter((member) => {
					if (member.memberType === null || member.memberType === undefined) {
						return member;
					}
				});
			}

			setOrderedList(results);
		}

		setDirectoryLoading(false);
	}, [list, sortOrder, orderBy, memberType]);

	//getDirectory
	useEffect(() => {
		if (!list) {
			if (authCtx.isLoggedIn && selection === 'Not Suspended/Deleted') {
				setDirectoryLoading(true);

				const getDirectory = async () => {
					//gets the leadership positions and passes this to the GetDirectory to find the positions of the office members
					const leadershipPositions = [];
					const querySnapshot = await getDocs(collection(db, 'leadership'));
					querySnapshot.forEach((document) => {
						console.log(document)
						let data = document.data();
						if (data.currentPerson.startDate && data.currentPerson.startDate !== null) {
							data.currentPerson.startDate = data.currentPerson.startDate.toDate();
						}
						if (data.currentPerson.endDate && data.currentPerson.endDate !== null) {
							data.currentPerson.endDate = data.currentPerson.endDate.toDate();
						}
						if (data.futurePerson.startDate && data.futurePerson.startDate !== null) {
							data.futurePerson.startDate = data.futurePerson.startDate.toDate();
						}
						if (data.futurePerson.endDate && data.futurePerson.endDate !== null) {
							data.futurePerson.endDate = data.futurePerson.endDate.toDate();
						}
						if (data.active) {
							leadershipPositions.push({ id: document.id, ...data });
						}
					});
					const directoryList = await GetDirectory(authCtx.token, leadershipPositions);
					setList(directoryList);
				};
				getDirectory();
			}

			if (authCtx.isLoggedIn && selection === 'Suspended') {
				setDirectoryLoading(true);

				const functions = getFunctions();
				const getDirectory = httpsCallable(functions, 'getDirectorySuspendedUsers');
				getDirectory({ admin: claimsCtx?.claims?.admin }).then((response) => {
					const data = response.data.response;
					setList(data);
				});
			}

			if (authCtx.isLoggedIn && selection === 'Deleted') {
				setDirectoryLoading(true);

				const functions = getFunctions();
				const getDirectory = httpsCallable(functions, 'getDirectoryDeletedUsers');
				getDirectory({ admin: claimsCtx?.claims?.admin }).then((response) => {
					const data = response.data.response;
					// console.log(data);
					if (data === 'Error loading users') {
						setList('No Deleted Users');
					} else {
						setList(data);
					}
				});
			}
		}
	}, [selection]);

	//handles undeleting a member
	const handleUndelete = (deletedUser, orgUnit) => {
		setDirectoryLoading(true);

		const functions = getFunctions();
		const getDirectory = httpsCallable(functions, 'undeleteUserAccount');
		getDirectory({ id: deletedUser.id, orgUnit: orgUnit.orgUnitPath }).then((response) => {
			// console.log(response);
			if ((response.data.status === 200) | (response.data.status === 204)) {
				alertCtx.setMessage(`${deletedUser.email} was undeleted successfully.`);
				alertCtx.setSeverity('success');
				alertCtx.setTimer(10000);
				alertCtx.setActive(true);
				// setDeletedUserInfo({ display: true, success: true, email: deletedUserInfo.email });
				handleMemberSelection(deletedUser.email);
				setSelection('Suspended');
			} else {
				alertCtx.setMessage(`${deletedUser.email} was <strong>not</strong> undeleted.`);
				alertCtx.setSeverity('error');
				alertCtx.setActive(true);
				// setDeletedUserInfo({ display: true, success: false, email: deletedUserInfo.email });
				setSelection('Deleted');
			}
		});

		// setTimeout(() => {
		// 	setDeletedUserInfo({ display: false, success: null, email: '' });
		// }, 5000);
	};

	//clears the current selected member
	const handleSelectionChange = (e) => {
		handleClearSelectedMember();
		setSelection(e);
		if (e !== 'Deleted') setNoDeletedUsers(false);
	};

	return (
		<Grid container spacing={1}>
			<Grid xs={12}>
				<PageCards>
					<Typography variant='h3' color={'primary'} textAlign='center'>
						EPT Directory
					</Typography>
				</PageCards>
			</Grid>

			{/* do not display when on md or larger screens (>= 900 px) */}
			<Grid sm={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
				<PageCards>
					<CheckToken>
						<DirectoryForm
							member={selectedMember}
							handleUpdate={handleUpdate}
							handleClearSelectedMember={handleClearSelectedMember}
							newInfo={newInfo}
							isLoading={memberLoading}
							memberInfo={memberInfo}
							list={list}
							onClose={handleClearSelectedMember}
							selection={selection}
							onUndelete={handleUndelete}
							noDeletedUsers={noDeletedUsers}
						/>
					</CheckToken>
				</PageCards>
			</Grid>

			{/* directory list */}
			<Grid xs={12} md={4}>
				<PageCards>
					<CheckToken>
						<DirectoryList
							userEmail={authCtx.email}
							loggedIn={authCtx.isLoggedIn}
							onMemberSelection={handleMemberSelection}
							list={orderedList}
							onOrderByChange={handleOrderByChange}
							onSortOrderChange={handleSortOrderChange}
							onMemberTypeChange={handleMemberTypeChange}
							isLoading={directoryLoading}
							orderBy={orderBy}
							sortOrder={sortOrder}
							memberType={memberType}
							selection={selection}
							onSelectionChange={handleSelectionChange}
						/>
					</CheckToken>
				</PageCards>
			</Grid>

			{/* do not display when on xs or sm screens (< 900 px) */}
			<Grid md={8} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
				<PageCards>
					<CheckToken>
						<DirectoryForm
							member={selectedMember}
							handleUpdate={handleUpdate}
							handleClearSelectedMember={handleClearSelectedMember}
							newInfo={newInfo}
							isLoading={memberLoading}
							memberInfo={memberInfo}
							list={list}
							onClose={handleClearSelectedMember}
							selection={selection}
							onUndelete={handleUndelete}
							noDeletedUsers={noDeletedUsers}
						/>
					</CheckToken>
				</PageCards>
			</Grid>

			<br></br>
		</Grid>
	);
}

export default Directory;
