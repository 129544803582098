import { useEffect, useState, useContext } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import PageCards from "../../components/ui/PageCards";
import Typography from "@mui/material/Typography";
import CheckToken from "../../components/auth/CheckToken";
import AngieListTable from "../../components/pscinfo/AngieListTable";
import CreateAngieForm from "../../components/pscinfo/CreateAngieForm";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../App";
import CustomClaimsContext from "../../components/auth/CustomClaimsContext";

const AngieList = () => {
    const claimsCtx = useContext(CustomClaimsContext);
    const [items, setItems] = useState([]);
    useEffect(() => {
        const getAngieList = async () => {
            const tmpList = [];
            const angieRef = collection(db, 'PSC', 'AngiesList', 'AngiesList');
            let response;
            if (claimsCtx?.claims?.pscMember) { response = await getDocs(angieRef); }
            else { response = await getDocs(query(angieRef, where("Visible", "==", true))); }
            response.forEach((item) => {
                const data = item.data();
                tmpList.push({id:item.id, ...data});
            })
            setItems(tmpList);
        }
        getAngieList();
    },[claimsCtx?.claims?.pscMember]);
    const handleAddItem = (item) => {
        setItems([...items, item]);
    }
    return (
        <>
            <Grid xs={12}>
                <PageCards>
                    <Typography variant="h3" color="primary" textAlign={"center"}>
                        Angie’s List
                    </Typography>
                </PageCards>
            </Grid>
            <Grid xs={12}>
                <PageCards>
                    <CheckToken>
                        <AngieListTable
                            pscMember={claimsCtx?.claims?.pscMember}
                            list={items}
                            email={claimsCtx.claims.email}
                            name={claimsCtx.claims.name}
                        ></AngieListTable>
                    </CheckToken>
                </PageCards>
            </Grid>
            <Grid xs={12}>
                <PageCards>
                    <CheckToken>
                        <CreateAngieForm
                            handleAddItem={handleAddItem}
                            email={claimsCtx.claims.email}
                            name={claimsCtx.claims.name}
                        />
                    </CheckToken>
                </PageCards>
            </Grid>
        </>
    )
}

export default AngieList;