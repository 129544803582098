import { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import PageCards from '../../components/ui/PageCards';
import Typography from '@mui/material/Typography';
import CheckToken from '../../components/auth/CheckToken';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../App';
import CustomClaimsContext from '../../components/auth/CustomClaimsContext';
import CommensalityTable from '../../components/pscinfo/CommensalityTable';
import CreateCommensality from '../../components/pscinfo/CreateCommensality';
import { Box } from '@mui/material';

const Commensality = () => {
	const claimsCtx = useContext(CustomClaimsContext);
	const [groups, setGroups] = useState([]);

	useEffect(() => {
		const fetchGroups = async () => {
			const tmpGroups = [];
			const commensalityRef = collection(db, 'PSC', 'Commensality', 'Commensality');
			let commensalityDocs;
			if (claimsCtx?.claims?.pscMember) {
				commensalityDocs = await getDocs(commensalityRef);
			} else {
				commensalityDocs = await getDocs(query(commensalityRef, where('Visible', '==', true)));
			}
			commensalityDocs.forEach((item) => {
				const data = item.data();
				tmpGroups.push({
					...data,
					id: item.id,
				});
			});
			setGroups(tmpGroups);
		};
		fetchGroups();
	}, [claimsCtx?.claims?.pscMember]);

	const handleAddItem = (item) => {
		setGroups([...groups, item]);
	};

	return (
		<>
			<Grid xs={12}>
				<PageCards>
					<Typography variant='h3' color='primary' textAlign={'center'}>
						Commensality
					</Typography>

					<CheckToken>
						<Box mb={3}>
							<CommensalityTable
								list={groups}
								pscMember={claimsCtx?.claims?.pscMember}
								email={claimsCtx.claims.email}
							/>
						</Box>
					</CheckToken>
				</PageCards>
			</Grid>

			{(claimsCtx?.claims?.pscMember) && (
				<Grid xs={12}>
					<PageCards>
						<CreateCommensality email={claimsCtx.claims.email} handleAddItem={handleAddItem} />
					</PageCards>
				</Grid>
			)}
		</>
	);
};

export default Commensality;
