import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import MyInfoPage from './pages/MyInfoPage.js';
import Directory from './pages/Directory.js';
import Leadership from './pages/Leadership.js';
import OrgChart from './pages/OrgChart.js';
import BulkUpdate from './pages/BulkUpdate.js';
import TickLogs from './pages/TickLogs.js';
import LOAandSabbatical from './pages/LOAandSabbatical.js';
import Dashboards from './pages/Dashboards.js';
import DashboardSettings from './components/dashboard/DashboardSettings.js';
import Home from './pages/Home.js';
import Login from './pages/Login.js';
import ProtectedRoutes from './ProtectedRoutes.js';
import LeadershipPage from './pages/LeadershipPage.js';
import People from './pages/People.js';
import PublicIDs from './pages/PublicIDs.js';
import UniqueIDs from './pages/UniqueIDs.js';
import Settings from './pages/Settings.js';
import Spaces from './pages/Spaces.js';
import SendEmailAs from './pages/SendEmailAs.js';
import LoadingSpinner from './components/ui/LoadingSpinner.js';
import Layout from './components/layout/Layout';
import CustomClaimsContext from './components/auth/CustomClaimsContext.js';
import firebaseConfigSettings from './firebaseConfig.js';
import Verification from './components/verification/Verification.js';
import UserVerificationStats from './components/verification/UserVerificationStats.js';
import Voting from './components/voting/Voting.js';
import Vote from './components/voting/Vote.js';
import Vacation from './pages/Vacation.js';
import VacationAdmin from './components/outside_US/VacationAdmin.js';

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import PSC from './pages/PSC/PSC.js';
import Main from './pages/PSC/Main.js';
import Resources from './pages/PSC/Resources.js';
import AngieList from './pages/PSC/AngieList.js';
import SensitiveIssues from './pages/PSC/SensitiveIssues.js';
import Commensality from './pages/PSC/Commensality.js';
import EptWideEvents from './pages/PSC/EptWideEvents.js';

const firebaseConfig = firebaseConfigSettings;
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

/* Material UI Theme */
export const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 800,
			lg: 1200,
			xl: 1536,
		},
	},
	typography: {
		button: {
			textTransform: 'none',
		},
	},
	palette: {
		type: 'light',
		primary: {
			main: '#1F2253',
		},
		secondary: {
			main: '#5b7fb4',
		},
		info: {
			main: '#2196f3',
		},
		success: {
			main: '#4caf50',
		},
		error: {
			main: '#de433e',
		},
		cancel: {
			main: '#b0bec5',
		},
		button: {
			main: '#3A6FD1',
			dark: '#254788',
			light: '#3D7BF2',
			contrastText: '#fff',
		},
		deleteButton: {
			light: '#ef5350',
			main: '#d32f2f',
			dark: '#b71c1c',
			contrastText: '#fff',
		},
		saveButton: {
			light: '#9fa8da',
			main: '#3949ab',
			dark: '#42a5f5',
			contrastText: '#fff',
		},
		otherButton: {
			light: '#9575cd',
			main: '#5e35b1',
			dark: '#311b92',
			contrastText: '#fff',
		},
		selectedButton: {
			light: '#90caf9',
			main: '#90caf9',
			dark: '#90caf9',
		},
		unSelectedButton: {
			light: '#9b9ba2',
			main: '#9b9ba2',
			dark: '#515155',
		},
		lessThan90Percent: {
			light: '#db5858',
			dark: '#D84545',
			main: '#C12929',
			contrastText: '#FFF',
		},
		within10Percent: {
			light: '#fbcc57',
			main: '#fbc02d',
			dark: '#af861f',
			contrastText: '#000',
		},
		greaterThan100Percent: {
			light: '#6fbf73',
			main: '#7BB662', //'#49B654',
			dark: '#3D8C40',
			contrastText: '#000',
		},
		voteApprove: {
			light: '#F1FFF3',
			main: '#006400',
			contrastText: '#FFF',
		},
		voteDoNotApprove: {
			light: '#FFF1F1',
			main: '#8B0000',
			contrastText: '#FFF',
		},
		voteAbstain: {
			light: '#EAF5F9',
			main: '#1E90FF',
			contrastText: '#FFF',
		},
		voteRequestInPersonMeeting: {
			light: '#FFF8EC',
			main: '#FF8C00',
			contrastText: '#FFF',
		},
	},
});

function App() {
	logEvent(analytics, 'notification_received');
	const claimsCtx = useContext(CustomClaimsContext);

	return (
		<ThemeProvider theme={theme}>
			<Layout>
				{!claimsCtx?.claimsLoaded && (
					<Routes>
						<Route path='/login' element={<Login />} />
					</Routes>
				)}
				{claimsCtx?.claimsLoaded && (
					<Routes>
						<Route path='/login' element={<Login />} />
						<Route path='/people/:PublicID' element={<People />} />
						<Route path='/verification/:UniqueVerificationId' element={<Verification />} />
						<Route path='/vote/:documentID/:email/:vote' element={<Vote />} />
						<Route element={<ProtectedRoutes />}>
							<Route path='/' element={<Home />} />
							<Route path='/psc' element={<PSC />} />
							<Route path='/psc/main' element={<Main />} />
							<Route path='/psc/resources' element={<Resources />} />
							<Route path='/psc/angieslist' element={<AngieList />} />
							<Route path='/psc/sensitiveissues' element={<SensitiveIssues />} />
							<Route path='/psc/commensality' element={<Commensality />} />
							<Route path='/psc/eptwideevents' element={<EptWideEvents />} />
							<Route path='/dashboards' element={<Dashboards />} />
							<Route path='/directory' element={<Directory />} />
							<Route
								path='/dashboardsettings'
								element={claimsCtx?.claims?.admin ? <DashboardSettings /> : <Navigate to='/dashboard' />}
							/>
							<Route path='/leadership' element={<Leadership />} />
							<Route path='leadershipPage' element={<LeadershipPage />} />
							<Route path='/myinfo' element={<MyInfoPage />} />
							<Route path='/orgChart' element={<OrgChart />} />
							<Route path='/spaces' element={<Spaces />} />
							<Route path='/sendEmailAs' element={<SendEmailAs />} />
							<Route path='/tickLogs' element={<TickLogs />} />
							<Route path='/vacation' element={<Vacation />} />
							<Route
								path='/voting'
								element={
									claimsCtx?.claims?.memberType === 'Physician' && claimsCtx?.claims?.isEPT ? (
										<Voting />
									) : (
										<Navigate to='/' />
									)
								}
							/>
							<Route path='/bulkupdate' element={claimsCtx?.claims?.admin ? <BulkUpdate /> : <Navigate to='/' />} />
							<Route path='/settings' element={claimsCtx?.claims?.admin ? <Settings /> : <Navigate to='/' />} />
							<Route
								path='/loaSabbatical'
								element={claimsCtx?.claims?.admin ? <LOAandSabbatical /> : <Navigate to='/' />}
							/>
							<Route path='/publicIDs' element={claimsCtx?.claims?.admin ? <PublicIDs /> : <Navigate to='/' />} />
							<Route path='/uniqueIDs' element={claimsCtx?.claims?.admin ? <UniqueIDs /> : <Navigate to='/' />} />
							<Route
								path='/userVerificationStats'
								element={claimsCtx?.claims?.admin ? <UserVerificationStats /> : <Navigate to='/' />}
							/>
							<Route
								path='/vacationadmin'
								element={claimsCtx?.claims?.admin ? <VacationAdmin /> : <Navigate to='/' />}
							/>
						</Route>
						<Route path='*' element={<Navigate to='/' />} />
					</Routes>
				)}
			</Layout>
		</ThemeProvider>
	);
}

export default App;
