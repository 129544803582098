import { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';

import PageCards from '../../components/ui/PageCards';
import CheckToken from '../../components/auth/CheckToken';
import ResourcesTable from '../../components/pscinfo/ResourcesTable';
import CreateResourceForm from '../../components/pscinfo/CreateResourceForm';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../App';
import CustomClaimsContext from '../../components/auth/CustomClaimsContext';

const Resources = () => {
    const claimsCtx = useContext(CustomClaimsContext);
    const [resourLoading, setResourceLoading] = useState(false);
    const [resourcesList, setResourcesList] = useState([]);
    useEffect(() => {
        const getResourcesList = async () => {
            const tmpList = [];
            const pscCollectionRef = collection(db, 'PSC', 'Resources', 'Resources');
            let querySnapshot;
            setResourceLoading(true);
            if (claimsCtx?.claims?.pscMember) { querySnapshot = await getDocs(pscCollectionRef); }
            else { querySnapshot = await getDocs(query(pscCollectionRef, where("Visible", "==", true))); }
            querySnapshot.forEach((item) => {
                const data = item.data();
                tmpList.push({id:item.id, ...data});
            })
            setResourcesList(tmpList);
            setResourceLoading(false)
        }
        getResourcesList();
    },[claimsCtx?.claims?.pscMember]);

    const handleAddItem = (item) => {
        setResourcesList([...resourcesList, item]);
    }
    
    const updateResourceList = (one, type='update') => {
        if (type === 'update') {
            const newResourcesList = resourcesList.map((item) => {
                if (item.id === one.id) return one;
                else return item;
            })
            setResourcesList(newResourcesList);
        } else {
            setResourcesList(resourcesList.filter((item) => item.id !== one.id));
        }
    }
    return (
        <>
            <Grid xs={12}>
                {
                    resourLoading ? (
                        <PageCards>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <LoadingSpinner />
                            </Box>
                        </PageCards>
                    ) : (
                        <CheckToken>
                            <ResourcesTable 
                                list={resourcesList} 
                                pscMember={claimsCtx?.claims?.pscMember}
                                updateResourceList={updateResourceList}
                            ></ResourcesTable>
                        </CheckToken>
                    )
                }
            </Grid>
            {
                claimsCtx?.claims?.pscMember && (
                    <Grid xs={12}>
                        <PageCards>
                            <CheckToken>
                                <CreateResourceForm handleAddItem={handleAddItem}/>
                            </CheckToken>
                        </PageCards>
                    </Grid>
                )
            }
        </>
    )
}

export default Resources;