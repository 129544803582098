import { Fragment, useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';

import TextEditor from "../textEditor/TextEditor";
import AlertContext from "../ui/AlertContext";
import SubmitPscData from "./SubmitPscData";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../App";

const CreateAngieForm = (props) => {
    const alertCtx = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCat, setSelectedCat] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [businessPhone, setBusinessPhone] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [resourceContent, setResourceContent] = useState({});
    useEffect(() => {
        const getCategories = async () => {
            const categoryRef = doc(db, 'settings', 'PSCCategories');
            try{
                const temp_cat = (await getDoc(categoryRef)).data().AngiesList;
                setCategories(temp_cat);
                if (temp_cat.length) setSelectedCat(temp_cat[0])
            } catch(error) {
                console.log(error);
            }
        }
        getCategories();
    },[]);

    const alert = (type, msg, duration = 5000) => {
        alertCtx.setSeverity(type);
        alertCtx.setTitle(msg);
        alertCtx.setActive(true);
        alertCtx.setTimer(duration);
    }
    const formatForm = () => {
        setBusinessAddress('');
        setBusinessName('');
        setBusinessPhone('');
        setSelectedCat(categories[0]);
        setResourceContent({});
    }

    const handleSubmit = async () => {
        if (!resourceContent.valid){
            alert('error', 'Blurb content must be filled');
            return;
        }
        const data = {
            blurb: resourceContent.text,
            SubmitterName: props.name,
            BusinessName: businessName,
            BusinessPhone: businessPhone,
            BusinessEmail: props.email,
            BusinessAddress: businessAddress,
            Category: selectedCat,
            Visible: false,
            Reviews: []
        }
        setIsLoading(true);
		const response = await SubmitPscData(data, 'AngiesList');
        console.log(response);
		if (response.data.code !== 200) {
            alert('error', 'Your blurb was <strong>not</strong> submitted. Try again.');
		} else {
            alert('success', 'Your blurb was submitted successfully.');
            props.handleAddItem({
                id: response.data.id,
                ...data
            })
		}
        formatForm();
		setIsLoading(false);
    }
    const handleChange = (e, type) => {
        switch(type) {
            case 'SelectedCat':
                setSelectedCat(e.target.value);
                break;
            case 'BusinessName':
                setBusinessName(e.target.value);
                break;
            case 'BusinessPhone':
                setBusinessPhone(e.target.value);
                break;
            case 'BusinessAddress':
                setBusinessAddress(e.target.value);
                break;
            default:
                break;
        }
    }
    const handleResourceChange = (e) => {
        setResourceContent(e);
    }
    return (
        <Fragment>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant="h4">Submit Entry</Typography>
                <FormControl fullWidth sx={{maxWidth: '400px'}} margin='dense'>
                    <InputLabel>Select Category</InputLabel>
                    <Select
                        value={selectedCat}
                        onChange={(e) => handleChange(e, 'SelectedCat')}
                        label="Select Category"
                        size="small"
                        fullWidth
                    >
                        {
                            categories.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
            <Box display='flex' justifyContent='space-between' gap='5px'>
                <TextField
                    label='Business name'
                    variant='outlined'
                    value={businessName}
                    onChange={(e) => handleChange(e, 'BusinessName')}
                    size="small"
                    fullWidth
                />
                <TextField
                    label='Business address'
                    variant='outlined'
                    value={businessAddress}
                    onChange={(e) => handleChange(e, 'BusinessAddress')}
                    size="small"
                    fullWidth
                    />
                <TextField
                    label='Business phone'
                    variant='outlined'
                    value={businessPhone}
                    onChange={(e) => handleChange(e, 'BusinessPhone')}
                    size="small"
                    sx={{ maxWidth: '400px' }}
                    fullWidth
                />
            </Box>
            <Box sx={{width: '100%'}} marginTop='5px'>
                <TextEditor
                    initialContent={resourceContent.text}
                    onChange={handleResourceChange}
                />
            </Box>
            <Box display='flex' justifyContent='right'>
                <Button
                    variant='contained'
                    color='button'
                    onClick={handleSubmit}
                    disabled={isLoading? true: false}
                >
                    Submit
                </Button>
            </Box>
        </Fragment>)
}

export default CreateAngieForm;