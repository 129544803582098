import { Fragment, useEffect, useMemo, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import AlertContext from '../ui/AlertContext';

import { TbEdit } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import DeleteConfirmDialog from './DeleteConfirmDialog';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../App';
import TextEditor from '../textEditor/TextEditor';
import getDateTimeFromTimestamp from '../localFunctions/getDateTimeFromTimestamp';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import generateTimeOptions from '../localFunctions/generateTimeOptions';
import { CircularProgress } from '@mui/material';

const UpcommingEventTable = (props) => {
	const alertCtx = useContext(AlertContext);
	const [rows, setRows] = useState([]);
	const [modalObj, setModalObj] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [deleteRowId, setDeleteRowId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const timeOptions = generateTimeOptions();
	useEffect(() => {
		setRows(props.list);
	}, [props.list]);
	const handleEditClick = (row) => {
        setModalObj(row);
        setModalOpen(true);
    }
    const handleDeleteClick = (row) => {
        setDeleteRowId(row.id);
        setConfirmOpen(true);
    }
	const handleChange = (e, type) => {
        if (type==="Date"){
            setModalObj({
                ...modalObj,
                [type]: e
            });
        } else {
            setModalObj({
                ...modalObj,
                [type]: e.target.value
            })
        }
    }
	const handleDeleteAction = async () => {
        if (!deleteRowId) {
            handleClose();
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Select event to delete, again');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            return;
        }
        const eventRef = doc(db, 'PSC', 'Events', 'Events',deleteRowId)
        try {
            await deleteDoc(eventRef);
            alertCtx.setSeverity('success');
            alertCtx.setTitle('Event successfully deleted!');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            updateResourceList(deleteRowId, 'delete');
            handleClose();
        } catch(error) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle(error.message);
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            handleClose();
        }
    }
	const handleResourceChange = (e) => {
        setModalObj({
            ...modalObj,
            Details: e.text
        })
    }
	const updateResourceList = (one, type='update') => {
        if ( type === 'update' ) {
            const newRows = rows.map((item) => {
                if (item.id === one.id) return one;
                else return item;
            })
            setRows(newRows);
        } else {
            const newRows = rows.filter((item) => item.id !== one);
            setRows(newRows);
        }
    }
	const handleSaveResource = async () => {
        const idx = modalObj.id;
        if ( !idx ) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Select the event to update, again');
            alertCtx.setTimer(5000);
            alertCtx.setActive(true);
            return;
        }
		setIsLoading(true);
        const eventRef = doc(db, 'PSC', 'Events', 'Events', idx);
        const tempObj = JSON.parse(JSON.stringify(modalObj));
        delete tempObj['id'];
        updateDoc(eventRef, tempObj)
            .then(() => {
                setModalOpen(false);
                updateResourceList(modalObj, 'update');
                alertCtx.setSeverity('success');
                alertCtx.setTitle("Selected event successfully updated!");
                alertCtx.setActive(true);
                alertCtx.setTimer(5000);
				setIsLoading(false);
            })
            .catch((error) => {
                setModalOpen(false);
                alertCtx.setSeverity('error');
                alertCtx.setTitle(error.message);
                alertCtx.setActive(true);
                alertCtx.setTimer(5000);
				setIsLoading(false);
            })
    }
	const handleClose = () => {
        setModalOpen(false);
        setConfirmOpen(false);
    }
	const columnEvents = useMemo(() => {
		return [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				id: 'Title',
				accessorFn: (row) => (row.Title ? row.Title : ''),
				header: 'Title',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Title}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Date',
				accessorFn: (row) => (row.Date ? getDateTimeFromTimestamp(row.Date) : ''),
				header: 'Date',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
						<p>{getDateTimeFromTimestamp(row.original.Date)}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Location',
				accessorFn: (row) => (row.Location ? row.Locaiton : ''),
				header: 'Location',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Location}</p>
						</Stack>
					</Box>
				),
			}
		];
	}, []);
	const columnPscEvents = useMemo(() => {
		return [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				id: 'Title',
				accessorFn: (row) => (row.Title ? row.Title : ''),
				header: 'Title',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Title}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Date',
				accessorFn: (row) => (row.Date ? getDateTimeFromTimestamp(row.Date) : ''),
				header: 'Date',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{getDateTimeFromTimestamp(row.original.Date)}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Location',
				accessorFn: (row) => (row.Location ? row.Locaiton : ''),
				header: 'Location',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Location}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Visible',
				accessorFn: (row) => (row.Visible ? row.Visible : ''),
				header: 'Visible',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<Typography
								variant='p'
								style={{
									color: '#444',
								}}
							>
								{row.original.Visible ? 'True' : 'False'}
							</Typography>
						</Stack>
					</Box>
				),
			},
			{
                id: 'action',
                header: 'Action',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({row}) => (
                    <Box display='flex' justifyContent='center' gap="5px">
                        <IconButton onClick={() => handleEditClick(row.original)}><TbEdit></TbEdit></IconButton>
                        <IconButton onClick={() => handleDeleteClick(row.original)}><MdDeleteOutline></MdDeleteOutline></IconButton>
                    </Box>
                )
            }
		];
	}, []);

	const table = useMaterialReactTable({
		columns: props.pscMember? columnPscEvents : columnEvents,
		data: rows,
		muiTablePaperProps: {
            elevation: 0,
            sx: {
                '& tr:nth-of-type(even)': {
                    backgroundColor: '#f5f5f5'
                }
            }
        },
        displayColumnDefOptions: {
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    align: 'center'
                },
                size: 120
            }
        },
        initialState: {
            density: 'compact',
            expanded: false,
            pagination: { pageIndex: 0, pageSize: 15 },
            sorting: [{ id: 'Title', desc: false}],
            columnVisibility: {
                id: false
            }
        },
		renderDetailPanel: ({row}) => (
			<Box>
				<Typography sx={{ color: 'red' }}>Event Time: {row.original.StartTime? row.original.StartTime : 'Unknown'} - {row.original.EndTime? row.original.EndTime: 'Unknown'}</Typography>
				<Box display='flex'>
					<Stack direction={'row'} spacing={1}>
						<div className="no-margin-p-left" dangerouslySetInnerHTML={{ __html: row.original.Details }}></div>
					</Stack>
				</Box>
			</Box>
        )
	})
	return (
		<Fragment>
			<Box width='100%'>
				<Typography variant='h4' textAlign='center'>
					Upcomming Events
				</Typography>
				<MaterialReactTable
					table={table}
				></MaterialReactTable>
				<Dialog open={modalOpen} onClose={handleClose} maxWidth="xl">
					<Box sx={{ padding: '5px' }}>
						<DialogTitle>Edit Upcomming Event</DialogTitle>
						<DialogContent>
							<Box mt={1} display="flex" justifyContent="space-between" gap="5px">
								<Box width='100%'>
									{/* display submitter name */}
									<TextField
										fullWidth
										size='small'
										margin='dense'
										value={modalObj.Title}
										onChange={(e) => handleChange(e, 'Title')}
										label="Title"
										hiddenLabel
										variant='outlined'
										color='secondary'
									/>
								</Box>
                                <Box width='50%'>
                                    <TextField
                                        label="Location"
                                        value={modalObj.Location}
                                        onChange={(e) => handleChange(e, 'Location')}
                                        variant='outlined'
                                        margin='dense'
                                        color='secondary'
                                        size="small"
                                        fullWidth
                                    />
								</Box>
								<Box width='50%'>
									{/* custom visible */}
									<FormControl fullWidth margin='dense'>
										<InputLabel id={`Visible`}>Visible</InputLabel>
										<Select
											id='CustomVisible'
											margin="dense"
											color='secondary'
											labelId='Visible'
											label='Visible'
											size="small"
											value={modalObj.Visible? true: false}
											onChange={(e) => handleChange(e, 'Visible')}
										>
											<MenuItem value={true}>True</MenuItem>
											<MenuItem value={false}>False</MenuItem>
										</Select>
									</FormControl>
								</Box>
							</Box>
                            <Box mt={1} display='flex' justifyContent='space-between' gap='5px'>
                                <Box width="100%" display='flex' justifyContent='right'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label='Event Date'
                                            value={
                                                modalObj.Date?.seconds
                                                    ? dayjs(getDateTimeFromTimestamp(modalObj.Date.seconds))
                                                    : dayjs(modalObj.Date)
                                            }
                                            onChange={(newValue) => {
                                                const value = newValue === null? null : newValue.$d;
                                                handleChange(value, 'Date')
                                            }}
                                            slotProps={{ textField: {size: 'small'}}}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box width='50%'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel htmlFor="start_time">Start Time</InputLabel>
                                        <Select
                                            color='primary'
                                            id="start_time"
                                            label="Start Time"
                                            value={modalObj.StartTime? modalObj.StartTime: ''}
                                            onChange={(e) => handleChange(e, 'StartTime')}
                                            size="small"
                                            fullWidth
                                        >
                                            {
                                                timeOptions.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box width='50%'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel htmlFor="end_time">End Time</InputLabel>
                                        <Select
                                            color='primary'
                                            id="end_time"
                                            label="End Time"
                                            value={modalObj.EndTime? modalObj.EndTime: ''}
                                            onChange={(e) => handleChange(e, 'EndTime')}
                                            size="small"
                                            fullWidth
                                        >
                                            {
                                                timeOptions.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
							<Box mt={1}>
                                <TextEditor
                                    initialContent={modalObj.Details}
                                    onChange={handleResourceChange}
                                />
							</Box>
						</DialogContent>
						<DialogActions>
							<Button variant='contained' onClick={handleClose} color='cancel'>
								Cancel
							</Button>
							{
                                isLoading ? (
                                    <Button variant="contained" size="small" color="cancel" disabled={true}>
										<CircularProgress color="inherit" size={22}/>
									</Button>
                                ) : (
                                    <Button
                                        variant='contained'
                                        onClick={handleSaveResource}
                                    >
                                        Save
                                    </Button>
                                )
                            }
						</DialogActions>
					</Box>
				</Dialog>
				<DeleteConfirmDialog
					open={confirmOpen}
					handleClose={handleClose}
					handleDeleteAction={handleDeleteAction}
				/>
			</Box>
		</Fragment>
	);
};

export default UpcommingEventTable;
