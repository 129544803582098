import { Fragment, useEffect, useMemo, useState, useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import AlertContext from '../ui/AlertContext';
import Tooltip from '../ui/Tooltip';

import { TbEdit } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import CloseSvg from '../images/close.svg';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import TextEditor from '../textEditor/TextEditor';
import getDateTimeFromTimestamp from '../localFunctions/getDateTimeFromTimestamp';
import UploadIcon from '../images/upload-icon.svg';
import SmallUploadIcon from '../images/small-upload-icon.svg';
import { db, storage } from '../../App';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, listAll, ref, updateMetadata, uploadBytes } from 'firebase/storage';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import generateTimeOptions from '../localFunctions/generateTimeOptions';

const ImageDetailPanel = (props) => {
    const [imageUrls, setImageUrls] = useState([]);
    const [folderName, setFolderName] = useState('');
    const [row, setRow] = useState({});
    const getAllImageUrls = async(folder) => {
        const listRef = ref(storage, folder);
        const imageUrls = [];
      
        try {
          const res = await listAll(listRef);
          for (const itemRef of res.items) {
            const url = await getDownloadURL(itemRef);
            imageUrls.push(url);
          }
        } catch (error) {
          console.log("Error fetching image URLs:", error);
        }
      
        return imageUrls;
    }
    useEffect(() => {
        setRow(props.row);
        setFolderName(`${props.row.original.Title}-${getDateTimeFromTimestamp(props.row.original.Date.seconds)}`)
    },[props.row])
    useEffect(() => {
        const fetchImageUrls = async () => {
          const urls = await getAllImageUrls(`PSC/Events/${folderName}`);
          setImageUrls(urls);
        };
    
        if (folderName) fetchImageUrls();
    }, [folderName]);
    return (
        <Box display='flex' justifyContent='space-between' gap='10px'>
            <Box
                width='200px'
                maxHeight='180px'
                minHeight="125px"
                height='100%'
                overflow='hidden'
                sx={{
                    "&:hover": {
                        background: "#c1bfbf"
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#d9d9d9',
                    position: 'relative'
                }}
            >
                {
                    (props.selectedId === row.id && props.imagePreview) ? (
                        <>
                            <img src={props.imagePreview} alt={row.original.Title} style={{ width: '100%', height: 'auto', filter: 'brightness(0.9)' }}/>
                            <Typography variant="h6" sx={{
                                position: 'absolute',
                                color: 'white',
                                fontFamily: 'unset',
                                fontWeight: '800',
                                opacity: '0.5',
                                letterSpacing: '3px'
                            }}>Preview</Typography>
                            <Box sx={{position: 'absolute', right: '0px', bottom: '0px', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Button variant="contained" size="small" color='cancel' onClick={props.handleUploadCancel}>Cancel</Button>
                                <Button variant="contained" size="small" onClick={() => props.handleUploadProcess(row.original)}>Upload</Button>
                            </Box>
                        </>
                    ) : (
                        (!imageUrls || !imageUrls.length) ? (
                            <img src={UploadIcon} alt="Upload" style={{ width: '100px', margin: 'auto', marginTop: '9px', cursor: 'pointer' }} onClick={() => props.handleUploadClick(row.id)}/>
                        ) : (
                            <Fragment>
                                <img src={imageUrls[0]} alt={row.original.Title} style={{ width: '200px', height: 'auto'}} onClick={() => props.handleImageClick(imageUrls[0])} />
                                <Box display='flex' justifyContent='right' alignItems='center' width='100%' position='absolute' bottom="0px">
                                    <Tooltip title="Share your image">
                                        <Button variant="contained" size="small" onClick={() => props.handleUploadClick(row.id)} sx={{
                                            background: '#62217d',
                                            opacity: '0.8',
                                            "&:hover": {
                                                opacity: "1",
                                                background: '#62217d'
                                            }
                                        }}>
                                            <img src={SmallUploadIcon} alt="Upload"/>
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Fragment>
                        )
                    )
                }
                <input style={{display: 'none'}} id={`input-${row.id}`} type="file" onChange={(e) => props.handlePhotoChange(e, row.id)} />
            </Box>
            <Box width='100%'>
				<Typography sx={{ color: 'red' }}>Event Time: {row.original?.StartTime? row.original.StartTime : 'Unknown'} - {row.original?.EndTime? row.original.EndTime: 'Unknown'}</Typography>
                <div className="no-margin-p-left" dangerouslySetInnerHTML={{ __html: row.original?.Details }}></div>
                <Box width='100%' marginTop='15px'>
                    <Grid container spacing={2} columns={6} justifyContent='left' gap="5px">
                        {
                            imageUrls.map((url, index) => {
                                if (index) {
                                    return <Box width="120px" height="120px" overflow='hidden' key={index}>
                                            <img src={url} alt={`event-${index}`} className='event-image-item' onClick={() => props.handleImageClick(url)}/>
                                        </Box>
                                } else return null
                            })
                        }
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

const PastEventTable = (props) => {
	const alertCtx = useContext(AlertContext);
	const [rows, setRows] = useState([]);
	const [modalObj, setModalObj] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
    const [imageOpen, setImageOpen] = useState(false);
	const [deleteRowId, setDeleteRowId] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');
    const [version, setVersion] = useState(0)
    const timeOptions = generateTimeOptions();
	useEffect(() => {
		setRows(props.list);
	}, [props.list]);
    useEffect(() => {
        if (selectedFile) {
            setImagePreview(URL.createObjectURL(selectedFile))
        } else {
            setImagePreview(null);
        }
    },[selectedFile])
    const uploadImage = async (folderName, file, userName) => {
        const storageRef = ref(storage, `PSC/Events/${folderName}/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        const metadata = {
            customMetadata: {
                userName: userName
            }
        }
        try{
            await updateMetadata(storageRef, metadata);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        } catch (error) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle(error.message);
            alertCtx.setActive(true);
            alertCtx.setTimer(3000);
            return null;
        }
    }
    const handleImageClick = (url) => {
        setSelectedImageUrl(url);
        setImageOpen(true);
    }
	const handleEditClick = (row) => {
        console.log(row);
        setModalObj(row);
        setModalOpen(true);
    }
    const handleDeleteClick = (row) => {
        setDeleteRowId(row.id);
        setConfirmOpen(true);
    }
	const handleChange = (e, type) => {
        if (type==="Date"){
            setModalObj({
                ...modalObj,
                [type]: e
            });
        } else {
            setModalObj({
                ...modalObj,
                [type]: e.target.value
            })
        }
    }
	const handleDeleteAction = async () => {
        if (!deleteRowId) {
            handleClose();
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Select event to delete, again');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            return;
        }
        const eventRef = doc(db, 'PSC', 'Events', 'Events',deleteRowId)
        try {
            await deleteDoc(eventRef);
            alertCtx.setSeverity('success');
            alertCtx.setTitle('Event successfully deleted!');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            updateResourceList(deleteRowId, 'delete');
            handleClose();
        } catch(error) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle(error.message);
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            handleClose();
        }
    }
	const handleResourceChange = (e) => {
        setModalObj({
            ...modalObj,
            Details: e.text
        })
    }
    const handleUploadClick = (e) => {
        document.getElementById(`input-${e}`).click();
    }
    const handlePhotoChange = (e, type) => {
        if (selectedId !== null) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle('You have to complete previous selected image');
            alertCtx.setActive(true);
            alertCtx.setTimer(3000);
            return;
        }
        setSelectedId(type);
        setSelectedFile(e.target.files[0]);
    }
    const handleUploadProcess = async (e) => {
        const folderName = `${e.Title}-${getDateTimeFromTimestamp(e.Date.seconds)}`;
        if ( !folderName || !selectedFile || !props.name ) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Select Image again');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            return;
        }
        const result = await uploadImage(folderName, selectedFile, props.name );
        if (result){
            alertCtx.setSeverity('success');
            alertCtx.setTitle('Selected image successfully uploaded');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            setSelectedFile(null);
            setSelectedId(null);
            setImagePreview(null);
            setVersion(version+1);
        }
    }
    const handleUploadCancel = () => {
        setSelectedId(null);
        setSelectedFile(null);
        setImagePreview(null);
    }
	const updateResourceList = (one, type='update') => {
        if ( type === 'update' ) {
            const newRows = rows.map((item) => {
                if (item.id === one.id) return one;
                else return item;
            })
            setRows(newRows);
        } else {
            const newRows = rows.filter((item) => item.id !== one);
            setRows(newRows);
        }
    }
	const handleSaveResource = async () => {
        const idx = modalObj.id;
        if ( !idx ) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Select the event to update, again');
            alertCtx.setTimer(5000);
            alertCtx.setActive(true);
            return;
        }
        setIsLoading(true);
        const eventRef = doc(db, 'PSC', 'Events', 'Events', idx);
        const tempObj = JSON.parse(JSON.stringify(modalObj));
        delete tempObj['id'];
        updateDoc(eventRef, tempObj)
            .then(() => {
                setModalOpen(false);
                updateResourceList(modalObj, 'update');
                alertCtx.setSeverity('success');
                alertCtx.setTitle("Selected event successfully updated!");
                alertCtx.setActive(true);
                alertCtx.setTimer(5000);
                setIsLoading(false);
            })
            .catch((error) => {
                setModalOpen(false);
                alertCtx.setSeverity('error');
                alertCtx.setTitle(error.message);
                alertCtx.setActive(true);
                alertCtx.setTimer(5000);
                setIsLoading(false);
            })
    }
	const handleClose = () => {
        setModalOpen(false);
        setConfirmOpen(false);
        setImageOpen(false);
    }
	const columnEvents = useMemo(() => {
		return [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				id: 'Title',
				accessorFn: (row) => (row.Title ? row.Title : ''),
				header: 'Title',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Title}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Date',
				accessorFn: (row) => (row.Date ? getDateTimeFromTimestamp(row.Date) : ''),
				header: 'Date',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
						<p>{getDateTimeFromTimestamp(row.original.Date)}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Location',
				accessorFn: (row) => (row.Location ? row.Locaiton : ''),
				header: 'Location',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Location}</p>
						</Stack>
					</Box>
				),
			}
		];
	}, []);
	const columnPscEvents = useMemo(() => {
		return [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				id: 'Title',
				accessorFn: (row) => (row.Title ? row.Title : ''),
				header: 'Title',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Title}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Date',
				accessorFn: (row) => (row.Date ? getDateTimeFromTimestamp(row.Date) : ''),
				header: 'Date',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{getDateTimeFromTimestamp(row.original.Date)}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Location',
				accessorFn: (row) => (row.Location ? row.Locaiton : ''),
				header: 'Location',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Location}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Visible',
				accessorFn: (row) => (row.Visible ? row.Visible : ''),
				header: 'Visible',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<Typography
								variant='p'
								style={{
									color: '#444',
								}}
							>
								{row.original.Visible ? 'True' : 'False'}
							</Typography>
						</Stack>
					</Box>
				),
			},
			{
                id: 'action',
                header: 'Action',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({row}) => (
                    <Box display='flex' justifyContent='center' gap="5px">
                        <IconButton onClick={() => handleEditClick(row.original)}><TbEdit></TbEdit></IconButton>
                        <IconButton onClick={() => handleDeleteClick(row.original)}><MdDeleteOutline></MdDeleteOutline></IconButton>
                    </Box>
                )
            }
		];
	}, []);
	const table = useMaterialReactTable({
		columns: props.pscMember? columnPscEvents : columnEvents,
		data: rows,
		muiTablePaperProps: {
            elevation: 0,
            sx: {
                '& tr:nth-of-type(even)': {
                    backgroundColor: '#f5f5f5'
                }
            }
        },
        displayColumnDefOptions: {
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    align: 'center'
                },
                size: 120
            }
        },
        initialState: {
            density: 'compact',
            expanded: false,
            pagination: { pageIndex: 0, pageSize: 15 },
            sorting: [{ id: 'Title', desc: false}],
            columnVisibility: {
                id: false
            }
        },
		renderDetailPanel: ({row}) => (
            <ImageDetailPanel
                row={row}
                selectedId={selectedId}
                imagePreview={imagePreview}
                handleUploadClick={handleUploadClick}
                handleUploadCancel={handleUploadCancel}
                handleUploadProcess={handleUploadProcess}
                handlePhotoChange={handlePhotoChange}
                handleImageClick={handleImageClick}
            />
        )
	})
	return (
		<Fragment>
			<Box width='100%'>
				<Typography variant='h4' textAlign='center'>
                    Past Events
				</Typography>
				<MaterialReactTable
                    key={version}
					table={table}
				></MaterialReactTable>
				<Dialog open={modalOpen} onClose={handleClose} maxWidth="xl">
					<Box sx={{ padding: '5px' }}>
						<DialogTitle>Edit Past Event</DialogTitle>
						<DialogContent>
							<Box mt={1} display="flex" justifyContent="space-between" gap="5px">
								<Box width='100%'>
									{/* display submitter name */}
									<TextField
										fullWidth
										size='small'
										margin='dense'
										value={modalObj.Title}
										onChange={(e) => handleChange(e, 'Title')}
										label="Title"
										hiddenLabel
										variant='outlined'
										color='secondary'
									/>
								</Box>
                                <Box width='50%'>
                                    <TextField
                                        label="Location"
                                        value={modalObj.Location}
                                        onChange={(e) => handleChange(e, 'Location')}
                                        variant='outlined'
                                        margin='dense'
                                        color='secondary'
                                        size="small"
                                        fullWidth
                                    />
								</Box>
								<Box width='50%'>
									{/* custom visible */}
									<FormControl fullWidth margin='dense'>
										<InputLabel id={`Visible`}>Visible</InputLabel>
										<Select
											id='CustomVisible'
											margin="dense"
											color='secondary'
											labelId='Visible'
											label='Visible'
											size="small"
											value={modalObj.Visible? true: false}
											onChange={(e) => handleChange(e, 'Visible')}
										>
											<MenuItem value={true}>True</MenuItem>
											<MenuItem value={false}>False</MenuItem>
										</Select>
									</FormControl>
								</Box>
							</Box>
                            <Box mt={1} display='flex' justifyContent='space-between' gap='5px'>
                                <Box width="100%" display='flex' justifyContent='right'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label='Event Date'
                                            value={
                                                modalObj.Date?.seconds
                                                    ? dayjs(getDateTimeFromTimestamp(modalObj.Date.seconds))
                                                    : dayjs(modalObj.Date)
                                            }
                                            onChange={(newValue) => {
                                                const value = newValue === null? null : newValue.$d;
                                                handleChange(value, 'Date')
                                            }}
                                            slotProps={{ textField: {size: 'small'}}}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box width='50%'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel htmlFor="start_time">Start Time</InputLabel>
                                        <Select
                                            color='primary'
                                            id="start_time"
                                            label="Start Time"
                                            value={modalObj.StartTime? modalObj.StartTime: ''}
                                            onChange={(e) => handleChange(e, 'StartTime')}
                                            size="small"
                                            fullWidth
                                        >
                                            {
                                                timeOptions.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box width='50%'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel htmlFor="end_time">End Time</InputLabel>
                                        <Select
                                            color='primary'
                                            id="end_time"
                                            label="End Time"
                                            value={modalObj.EndTime? modalObj.EndTime: ''}
                                            onChange={(e) => handleChange(e, 'EndTime')}
                                            size="small"
                                            fullWidth
                                        >
                                            {
                                                timeOptions.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
							<Box mt={1}>
                                <TextEditor
                                    initialContent={modalObj.Details}
                                    onChange={handleResourceChange}
                                />
							</Box>
						</DialogContent>
						<DialogActions>
							<Button variant='contained' onClick={handleClose} color='cancel'>
								Cancel
							</Button>
                            {
                                isLoading ? (
                                    <Button variant="contained" size="small" color="cancel" disabled={true}>
										<CircularProgress color="inherit" size={22}/>
									</Button>
                                ) : (
                                    <Button
                                        variant='contained'
                                        onClick={handleSaveResource}
                                    >
                                        Save
                                    </Button>
                                )
                            }
						</DialogActions>
					</Box>
				</Dialog>
                <Dialog
                    open={imageOpen}
                    onClose={handleClose}
                    maxWidth="xl"
                >
                    <DialogContent style={{ overflow: 'hidden', padding: "0px", position: 'relative' }}>
                        <img src={CloseSvg} alt="close" onClick={handleClose} style={{ position: 'absolute', width: '35px', height: '35px', top: "0px", right: '0px', cursor: 'pointer' }}/>
                        <img src={selectedImageUrl} alt={"loading..."} style={{ height: '100vh'}}/>
                    </DialogContent>
                </Dialog>
				<DeleteConfirmDialog
					open={confirmOpen}
					handleClose={handleClose}
					handleDeleteAction={handleDeleteAction}
				/>
			</Box>
		</Fragment>
	);
};

export default PastEventTable;
