import { useEffect, useState, useContext } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import PageCards from "../../components/ui/PageCards";
import Typography from '@mui/material/Typography'
import CheckToken from "../../components/auth/CheckToken";
import CreateSensitiveForm from "../../components/pscinfo/CreateSensitiveForm";
import SensitiveIssuesTable from "../../components/pscinfo/SensitiveIssuesTable";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../App";
import CustomClaimsContext from "../../components/auth/CustomClaimsContext";

const SensitiveIssues = () => {
    const claimsCtx = useContext(CustomClaimsContext);
    const [sensitiveItems, setSensitiveItems] = useState([]);
    useEffect(() => {
        const getSensitiveList = async () => {
            const tmpList = [];
            const sensitiveRef = collection(db, 'PSC', 'Sensitive', 'Sensitive');
            let querySnapshot;
            if (claimsCtx?.claims?.pscMember) { querySnapshot = await getDocs(sensitiveRef); }
            else { querySnapshot = await getDocs(query(sensitiveRef, where("Visible", "==", true))); }
            querySnapshot.forEach((item) => {
                const data = item.data();
                tmpList.push({id: item.id, ...data})
            })
            setSensitiveItems(tmpList)
        }
        getSensitiveList();
    },[claimsCtx?.claims?.pscMember])
    const handleAddItem = (item) => {
        setSensitiveItems([...sensitiveItems, item]);
    }
    return (
        <>
            <Grid xs={12}>
                <PageCards>
                    <Typography variant="h3" color="primary" textAlign={"center"}>
                        Sensitive Issues
                    </Typography>
                </PageCards>
            </Grid>
            <Grid xs={12}>
                <PageCards>
                    <CheckToken>
                        <SensitiveIssuesTable
                            pscMember={claimsCtx?.claims?.pscMember}
                            list={sensitiveItems}
                            email={claimsCtx.claims.email}
                            name={claimsCtx.claims.name}
                        ></SensitiveIssuesTable>
                    </CheckToken>
                </PageCards>
            </Grid>
            {
                claimsCtx?.claims?.pscMember && (
                    <Grid xs={12}>
                        <PageCards>
                            <CheckToken>
                                <CreateSensitiveForm handleAddItem={handleAddItem} />
                            </CheckToken>
                        </PageCards>
                    </Grid>
                )
            }
        </>
    )
}

export default SensitiveIssues;