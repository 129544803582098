import React, { createContext, useEffect, useState, useContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Create a new context
/**
 * Returns claims and claimsLoaded
 * if claimsLoaded is true, then the claims have been obtained if the user is logged in
 * if claimsLoaded is false, then no user is logged in
 */
const CustomClaimsContext = createContext({
	claims: null,
	claimsLoaded: null,
});

export const CustomClaimsContextProvider = ({ children }) => {
	const auth = getAuth();
	const [claims, setClaims] = useState(null);
	const [claimsLoaded, setClaimsLoaded] = useState(null);

	useEffect(() => {
		// Listen for auth state changes
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (!auth.currentUser) {
				setClaims({});
				setClaimsLoaded(true);
			}
			if (user) {
				// Fetch ID token result when the user is signed in
				user
					.getIdTokenResult()
					.then((res) => {
						setClaims(res.claims);
						setClaimsLoaded(true);
					})
					.catch((err) => {
						console.error(err);
						setClaims({});
						setClaimsLoaded(true);
					});
			}
		});

		// Cleanup subscription on component unmount
		return () => unsubscribe();
	}, [auth]);

	const contextValue = {
		claims: claims,
		claimsLoaded: claimsLoaded,
	};

	return <CustomClaimsContext.Provider value={contextValue}>{children}</CustomClaimsContext.Provider>;
};

export default CustomClaimsContext;

// Custom hook to use the context
export const useCustomClaims = () => useContext(CustomClaimsContext);
