import { useEffect, useState } from 'react';
import { collection, getDocs } from '@firebase/firestore';

import Grid from '@mui/material/Unstable_Grid2';

import { db } from '../../App';
import PageCards from '../../components/ui/PageCards';
import { Box, Typography } from '@mui/material';
import CheckToken from '../../components/auth/CheckToken';
// import PscMemberTable from '../../components/pscinfo/PscMemberTable';
import PscMemberGrid from '../../components/pscinfo/PscMemberGrid';
import { useContext } from 'react';
import AuthContext from '../../components/auth/authContext';
import LoadingSpinner from '../../components/ui/LoadingSpinner';

const Main = () => {
	const authCtx = useContext(AuthContext);
	const [psclist, setPsclist] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getPscList = async () => {
			setLoading(true);
			const tmpList = [];
			const querySnapshot = await getDocs(collection(db, 'leadership'));
			querySnapshot.forEach((item) => {
				const data = item.data();
				if (data.positionTitle === 'Peer Support' || data.positionTitle === 'Peer Support Chair') {
					if (data.currentPerson.startDate && data.currentPerson.startDate !== null) {
						data.currentPerson.startDate = data.currentPerson.startDate.toDate();
					}
					if (data.currentPerson.endDate && data.currentPerson.endDate !== null) {
						data.currentPerson.endDate = data.currentPerson.endDate.toDate();
					}
					if (data.futurePerson.startDate && data.futurePerson.startDate !== null) {
						data.futurePerson.startDate = data.futurePerson.startDate.toDate();
					}
					if (data.futurePerson.endDate && data.futurePerson.endDate !== null) {
						data.futurePerson.endDate = data.futurePerson.endDate.toDate();
					}
					if (data.active) {
						tmpList.push({ id: item.id, ...data });
					}
				}
			});
			setPsclist(tmpList);
			setLoading(false);
		};
		getPscList();
	}, []);

	return (
		<Grid xs={12}>
			<PageCards>
				<Typography variant='h3' color='primary' textAlign='center' mb={3}>
					PSC Members
				</Typography>

				<CheckToken>
					<Box mb={3}>
						{loading && <LoadingSpinner />}
						<PscMemberGrid list={psclist} token={authCtx.token} />
					</Box>
				</CheckToken>
			</PageCards>
		</Grid>
	);
};

export default Main;
