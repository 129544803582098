import React, { createContext, useEffect, useState, useRef } from 'react';

// Create a new context
const AlertContext = createContext({
	active: false,
	setActive: (active) => {},
	severity: '',
	setSeverity: (severity) => {},
	message: '',
	setMessage: (message) => {},
	timer: '',
	setTimer: (timer) => {},
	title: '',
	setTitle: (title) => {},
});

// Create a provider for the context
export const AlertContextProvider = ({ children }) => {
	const [active, setActive] = useState(null);
	const [severity, setSeverity] = useState('success');
	const [message, setMessage] = useState('');
	const [timer, setTimer] = useState(null);
	const [title, setTitle] = useState('');

	const timeoutRef = useRef(null);

	useEffect(() => {
		// Clear any existing timeout
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		if (active && timer > 0) {
			timeoutRef.current = setTimeout(() => {
				setActive(false);
			}, timer);
		}

		// Clean up the timeout when the component unmounts or before the next effect
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [active, timer]);

	const updateActiveState = (newState) => {
		setActive(newState);
	};

	const updateSeverity = (newSeverity) => {
		setSeverity(newSeverity);
	};

	const updateMessage = (newMessage) => {
		setMessage(newMessage);
	};

	const updateTimer = (newTimer) => {
		setTimer(newTimer);
	};

	const updateTitle = (newTitle) => {
		setTitle(newTitle);
	};

	useEffect(() => {
		setTimeout(() => {
			updateActiveState(false);
		}, timer);
	}, [timer]);

	const contextValue = {
		active: active,
		setActive: updateActiveState,
		severity: severity,
		setSeverity: updateSeverity,
		message: message,
		setMessage: updateMessage,
		timer: timer,
		setTimer: updateTimer,
		title: title,
		setTitle: updateTitle,
	};

	// Pass the state value and the update function as values in the Provider component
	return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>;
};

export default AlertContext;
