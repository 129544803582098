import { Fragment, useEffect, useMemo, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import AlertContext from '../ui/AlertContext';

import { TbEdit } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import TextEditor from '../textEditor/TextEditor';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import sendGrid from '../sendGrid/sendGrid';
import { db } from '../../App';

const SensitiveIssuesTable = (props) => {
	const alertCtx = useContext(AlertContext);
	const [rows, setRows] = useState([]);
	const [modalObj, setModalObj] = useState({});
	const [emailTitle, setEmailTitle] = useState('');
	const [emailContent, setEmailContent] = useState({});
	const [emailError, setEmailError] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [deleteRowId, setDeleteRowId] = useState(null);
	const [categories, setCategories] = useState([]);
	const [isLoading, setIsLoading] = useState(false)
	useEffect(() => {
		const getCategories = async() => {
			const categoryRef = doc(db, 'settings', 'PSCCategories');
			const temp_cat = (await getDoc(categoryRef)).data().SensitiveIssues;
			setCategories(temp_cat);
		}
		getCategories();
	},[]);
	useEffect(() => {
		setRows(props.list);
	}, [props.list]);
	const handleEditClick = (row) => {
        setModalObj(row);
        setModalOpen(true);
    }
    const handleDeleteClick = (row) => {
        setDeleteRowId(row.id);
        setConfirmOpen(true);
    }
	const handleChange = (e, type) => {
        setModalObj({
            ...modalObj,
            [type]: e.target.value
        })
    }
	const updateResourceList = (one, type='update') => {
        if ( type === 'update' ) {
            const newRows = rows.map((item) => {
                if (item.id === one.id) return one;
                else return item;
            })
            setRows(newRows);
        } else {
            const newRows = rows.filter((item) => item.id !== one);
            setRows(newRows);
        }
    }
	const handleResourceChange = (e) => {
        setModalObj({
            ...modalObj,
            Details: e.text
        })
    }
	const handleEmailTitleChange = (e) => {
		setEmailTitle(e.target.value);
	}
	const handleEmailContentChange = (e) => {
		setEmailContent(e);
		if (e.valid) setEmailError(false);
		else setEmailError(true);
	}
    const handleSaveResource = async () => {
        const idx = modalObj.id;
        if ( !idx ) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Select row to update, again');
            alertCtx.setTimer(5000);
            alertCtx.setActive(true);
            return;
        }
        const sensitiveRef = doc(db, 'PSC', 'Sensitive', 'Sensitive', idx);
        const tempObj = JSON.parse(JSON.stringify(modalObj));
        delete tempObj['id'];
        updateDoc(sensitiveRef, tempObj)
            .then(() => {
                setModalOpen(false);
                updateResourceList(modalObj, 'update');
                alertCtx.setSeverity('success');
                alertCtx.setTitle("Selected row successfully updated!");
                alertCtx.setActive(true);
                alertCtx.setTimer(5000);
            })
            .catch((error) => {
                setModalOpen(false);
                alertCtx.setSeverity('error');
                alertCtx.setTitle(error.message);
                alertCtx.setActive(true);
                alertCtx.setTimer(5000);
            })
    }
	const handleDeleteAction = async () => {
        if (!deleteRowId) {
            handleClose();
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Select item to delete, again');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            return;
        }
        const sensitiveRef = doc(db, 'PSC', 'Sensitive', 'Sensitive',deleteRowId)
        try {
            await deleteDoc(sensitiveRef);
            alertCtx.setSeverity('success');
            alertCtx.setTitle('Row successfully deleted!');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            updateResourceList(deleteRowId, 'delete');
            handleClose();
        } catch(error) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle(error.message);
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            handleClose();
        }
    }
	const handleSubmitEmail = async () => {
		if (!emailContent.valid) {
			setEmailError(true);
			return;
		}
		setIsLoading(true);
		const emailResponse = await sendGrid({
			to: 'peer-support-committee@ept911.com',
			cc: props.email,
			bcc: null,
			replyTo: '',
			plainText: emailContent.text,
			emailSubject: emailTitle,
			emailTitle: emailTitle,
			template_id: 'd-6bcb155d9eec426485c148c0cb14352c',
			emailHTML: emailContent.text,
			fromPerson: props.name,
			fromGroup: '',
			attachments: null
		})
		if (emailResponse.data.code === 200){
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Successfully reported your issue');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
			setIsLoading(false);
		} else {
			alertCtx.setSeverity('error');
			alertCtx.setTitle(emailResponse.data.message);
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
			setIsLoading(false);
		}
	}
	const handleClose = () => {
        setModalOpen(false);
        setConfirmOpen(false);
    }
	const columnSensitive = useMemo(() => {
		return [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				id: 'Title',
				accessorFn: (row) => (row.Title ? row.Title : ''),
				header: 'Title',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction={'row'} spacing={1}>
							<p>{row.original.Title}</p>
						</Stack>
					</Box>
				),
			},
			// {
			// 	id: 'blurb',
			// 	accessorFn: (row) => (row.Blurb ? row.Blurb: ''),
			// 	header: 'Blurb',
			// 	muiTableHeadCellProps: {
			// 		align: 'center'
			// 	},
			// 	Cell: ({ row }) => (
			// 		<Box display='flex' justifyContent='center'>
			// 			<Stack direction='row' spacing={1}>
			// 				<p>{row.original.Blurb}</p>
			// 			</Stack>
			// 		</Box>
			// 	)
			// },
			{
				id: 'Category',
				accessorFn: (row) => (row.Category ? row.Category : ''),
				header: 'Category',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Category}</p>
						</Stack>
					</Box>
				),
			}
		];
	}, []);
	const columnPscSensitive = useMemo(() => {
		return [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				id: 'Title',
				accessorFn: (row) => (row.Title ? row.Title : ''),
				header: 'Title',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction={'row'} spacing={1}>
							<p>{row.original.Title}</p>
						</Stack>
					</Box>
				),
			},
			// {
			// 	id: 'blurb',
			// 	accessorFn: (row) => (row.Blurb ? row.Blurb: ''),
			// 	header: 'Blurb',
			// 	muiTableHeadCellProps: {
			// 		align: 'center'
			// 	},
			// 	Cell: ({ row }) => (
			// 		<Box display='flex' justifyContent='center'>
			// 			<Stack direction='row' spacing={1}>
			// 				<p>{row.original.Blurb}</p>
			// 			</Stack>
			// 		</Box>
			// 	)
			// },
			{
				id: 'Category',
				accessorFn: (row) => (row.Category ? row.Category : ''),
				header: 'Category',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Category}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Visible',
				accessorFn: (row) => (row.Visible ? row.Visible : ''),
				header: 'Visible',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<Typography
								variant='p'
								style={{
									color: '#444',
								}}
							>
								{row.original.Visible ? 'True' : 'False'}
							</Typography>
						</Stack>
					</Box>
				),
			},
			{
                id: 'action',
                header: 'Action',
                muiTableHeadCellProps: {
                    align: 'center'
                },
                Cell: ({row}) => (
                    <Box display='flex' justifyContent='center' gap="5px">
                        <IconButton onClick={() => handleEditClick(row.original)}><TbEdit></TbEdit></IconButton>
                        <IconButton onClick={() => handleDeleteClick(row.original)}><MdDeleteOutline></MdDeleteOutline></IconButton>
                    </Box>
                )
            }
		];
	}, []);
	const table = useMaterialReactTable({
		columns: props.pscMember? columnPscSensitive: columnSensitive,
		data: rows,
		muiTablePaperProps: {
            elevation: 0,
            sx: {
                '& tr:nth-of-type(even)': {
                    backgroundColor: '#f5f5f5'
                }
            }
        },
        displayColumnDefOptions: {
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    align: 'center'
                },
                size: 120
            }   
        },
        initialState: {
            density: 'compact',
            expanded: false,
            pagination: { pageIndex: 0, pageSize: 15 },
            sorting: [{ id: 'Title', desc: false}],
            columnVisibility: {
                id: false
            }
        },
        renderDetailPanel: ({row}) => (
            <Box display='flex' justifyContent='center'>
                <Stack direction={'row'} spacing={1}>
					<div className='no-margin-p' dangerouslySetInnerHTML={{ __html: row.original.Details }}></div>
                </Stack>
            </Box>
        )
	})
	return (
		<Fragment>
			<Box xs={{ width: '100%' }}>
				{
					!props.pscMember ? (
						<Box sx={{ padding: '5px' }}>
							<DialogTitle>Request Help from the Peer Support Committee</DialogTitle>
							<DialogContent sx={{paddingTop: '0px', paddingBottom: '0px'}}>
								{ emailError && <Alert severity='error'>Email content must be filled</Alert> }
								<Box width="100%">
									<TextField
										fullWidth
										size='small'
										margin='dense'
										value={emailTitle}
										onChange={(e) => handleEmailTitleChange(e)}
										label="Subject"
										hiddenLabel
										variant='outlined'
										color='secondary'
										InputProps={{ sx: { borderRadius: '8px' } }}
									/>
								</Box>
								<TextEditor
									initialContent={emailContent.text}
									onChange={handleEmailContentChange}
								/>
							</DialogContent>
							<DialogActions sx={{paddingRight: '24px'}}>
								{
									isLoading ? (
										<Button variant="contained" size="small" color="cancel" disabled={true}>
											<CircularProgress color="inherit" size={22}/>
										</Button>
									) : (
										<Button
											variant='contained'
											color='button'
											onClick={handleSubmitEmail}
										>
											Send
										</Button>
									)
								}
							</DialogActions>
						</Box>
					) : (
						<MaterialReactTable table={table}></MaterialReactTable>
					)
				}
				<Dialog open={modalOpen} onClose={handleClose} maxWidth="xl">
					<Box sx={{ padding: '5px' }}>
						<DialogTitle>Edit Sensitive Issue</DialogTitle>
						<DialogContent>
							<Box mt={1} display="flex" justifyContent="space-between" gap="5px">
								<Box width="100%">
									<FormControl fullWidth margin='dense'>
										<InputLabel htmlFor="title">Title</InputLabel>
										<Select
											color='primary'
											id="title"
											label="Title"
											value={modalObj.Title}
											onChange={(e) => handleChange(e, 'Title')}
											size="small"
											fullWidth
										>
											<MenuItem value='Give Help'>
												Give Help
											</MenuItem>
											<MenuItem value='Get Help'>
												Get Help
											</MenuItem>
										</Select>
									</FormControl>
								</Box>
								<Box width="100%" display="flex" justifyContent="space-between" gap="5px">
									<FormControl fullWidth margin='dense'>
										<InputLabel id={`Category`}>Category</InputLabel>
										<Select
											id='CustomCategory'
											margin="dense"
											color='secondary'
											labelId='Category'
											label='Category'
											size="small"
											value={modalObj.Category}
											onChange={(e) => {handleChange(e, 'Category')}}
										>
											{
												categories.map((item, index) => (
													<MenuItem key={index} value={item}>{item}</MenuItem>
												))
											}
										</Select>
									</FormControl>

									{/* custom visible */}
									<FormControl fullWidth margin='dense'>
										<InputLabel id={`Visible`}>Visible</InputLabel>
										<Select
											id='CustomVisible'
											margin="dense"
											color='secondary'
											labelId='Visible'
											label='Visible'
											size="small"
											value={modalObj.Visible? true: false}
											onChange={(e) => {handleChange(e, 'Visible')}}
										>
											<MenuItem value={true}>True</MenuItem>
											<MenuItem value={false}>False</MenuItem>
										</Select>
									</FormControl>
								</Box>
							</Box>
							<Box>
							<TextEditor
								main={modalObj.Details}
								mainChangeHandler={handleResourceChange}
							/>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button variant='contained' onClick={handleClose} color='cancel'>
								Cancel
							</Button>
							<Button
								variant='contained'
								onClick={handleSaveResource}
							>
								Save
							</Button>
						</DialogActions>
					</Box>
				</Dialog>
				<DeleteConfirmDialog
					open={confirmOpen}
					handleClose={handleClose}
					handleDeleteAction={handleDeleteAction}
				/>
			</Box>
		</Fragment>
	);
};

export default SensitiveIssuesTable;
