import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

import { GrDocumentCsv, GrDocumentPdf } from "react-icons/gr";
import { BsFileZip, BsFiletypeDocx } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdInsertDriveFile, MdVideoFile } from "react-icons/md";
import { Box, Button, Typography } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";

const  isImageFile = (url) => {
    const baseUrl = url.split('?')[0];
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.svg'];
    const extension = (baseUrl.match(/\.[0-9a-z]+$/i) || [""])[0].toLowerCase();
    return imageExtensions.includes(extension);
}

const AttachmentPreview = (props) => {
    if (!props.preview) return '';

    if (props.preview.startsWith('blob:') || isImageFile(props.preview)) {
        return (
            <>
                <img 
                    src={props.preview} 
                    alt="Angie Preview" 
                    style={{ width: '100%', height: 'auto', filter: 'brightness(0.9)' }} 
                    onClick={() => props.handleUploadClick()} 
                />
                {props.isPreview && <Box sx={{position: 'absolute', right: '5px', top: '5px', 'cursor': 'pointer', fontSize: '1.5rem', "&:hover": {color: 'red'}}}>
                    <IoIosCloseCircleOutline  onClick={() => props.handleRemoveFile()}></IoIosCloseCircleOutline>
                </Box>}
            </>
        )
    }

    const baseUrl = props.preview.split('?')[0];
    const extension = (baseUrl.match(/\.[0-9a-z]+$/i) || [baseUrl])[0].toLowerCase().replace('.', '');

    let icon = <MdInsertDriveFile size={80}/>;
    switch (extension) {
        case 'pdf':
            icon = <GrDocumentPdf size={80}/>;
            break;
        case 'doc':
        case 'docx':
        case 'docm':
            icon = <BsFiletypeDocx size={80}/>;
            break;
        case 'csv':
            icon = <GrDocumentCsv size={80}/>;
            break;
        case 'xls':
        case 'xlsx':
        case 'xlsm':
        case 'xlsb':
            icon = <SiMicrosoftexcel size={80}/>;
            break;
        case 'mp4':
        case 'mov':
        case 'avi':
            icon = <MdVideoFile size={80}/>;
            break;
        case 'rar':
        case 'tar':
        case 'zip':
        case '7z':
            icon = <BsFileZip size={80}/>;
            break;
        default:
            icon = <MdInsertDriveFile size={80}/>;
            break;
    }

    return (
        <Stack
            m={2}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Button
                onClick={() => props.handleUploadClick()}
                variant='contained'
                size='large'
                color='button'
                sx={{
                    "&:hover" : {
                        background: '#2a65d3'
                    },
                    height: '120px',
                    width: '150px',
                    borderRadius: '8px',
                    margin: 'auto',
                    padding: '16px',
                    paddingBottom: '32px',
                }}
            >
                <Stack>
                    <Container>
                        { icon }
                    </Container>
                </Stack>
                <Typography variant="h6" sx={{
                        position: 'absolute',
                        color: 'white',
                        fontFamily: 'unset',
                        fontWeight: '800',
                        opacity: '0.5',
                        letterSpacing: '3px',
                        bottom: 0
                }}>{props.isPreview ? 'Preview' : 'Attachment'}</Typography>
            </Button>
            
            {props.isPreview && <Box sx={{position: 'absolute', right: '5px', top: '5px', 'cursor': 'pointer', fontSize: '1.5rem', "&:hover": {color: 'red'}}}>
                <IoIosCloseCircleOutline  onClick={() => props.handleRemoveFile()}></IoIosCloseCircleOutline>
            </Box>}
        </Stack>
    )
}


export default AttachmentPreview;