import React, { Fragment, useContext } from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import { Button, Container, Box, Typography, Stack } from '@mui/material';

import { AiOutlineDashboard } from 'react-icons/ai';
import { IoStatsChartSharp } from 'react-icons/io5';
import { BsChatLeftText, BsPersonVcard, BsFilePerson, BsKey, BsGear } from 'react-icons/bs';
import { GiOrganigram } from 'react-icons/gi';
import { BiSpreadsheet, BiCloudUpload } from 'react-icons/bi';
import { MdOutlineBeachAccess } from 'react-icons/md';
import { GoFileDirectory } from 'react-icons/go';
import { RiMailSendLine } from 'react-icons/ri';
import { BiStats } from 'react-icons/bi';
import { GiVote } from 'react-icons/gi';
import { MdOutlineAirplanemodeActive } from 'react-icons/md';
import { SiGoogledomains } from 'react-icons/si';

import PageCards from '../components/ui/PageCards';
import AuthContext from '../components/auth/authContext';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';

/**
 * Options include:
 * 	name - title of the icon
 *  admin - if set to true then only accessible by admin's
 * 	link - link to the page
 * 	iconHome - icon size for the home page, set to 80
 * 	iconMenu - icon size for the menu page, set to 20
 *  memberType - can restrict to different member types; options are 'Physician', 'APP - Tier 1', 'APP - Tier 2', 'APP - Tier 3', 'APP - Tier 4', 'APP - Tier 4T', 'Office'
 *  isEPT - if set to true then only accessible by EPT users and not OPT users
 */
export const icons = [
	{
		name: 'DASHBOARDS',
		admin: false,
		link: '/dashboards',
		iconHome: <IoStatsChartSharp size={80} />,
		iconMenu: <IoStatsChartSharp size={20} />,
	},
	{
		name: 'DIRECTORY',
		admin: false,
		link: '/directory',
		iconHome: <BsPersonVcard size={80} />,
		iconMenu: <BsPersonVcard size={20} />,
	},
	{
		name: 'GOOGLE SPACES',
		admin: false,
		link: '/spaces',
		iconHome: <BsChatLeftText size={80} />,
		iconMenu: <BsChatLeftText size={20} />,
	},
	{
		name: 'LEADERSHIP',
		admin: false,
		link: '/leadershipPage',
		iconHome: <GiOrganigram size={80} />,
		iconMenu: <GiOrganigram size={20} />,
	},
	{
		name: 'MY INFO',
		admin: false,
		link: '/myInfo',
		iconHome: <BsFilePerson size={80} />,
		iconMenu: <BsFilePerson size={20} />,
	},
	{
		name: 'PSC',
		admin: false,
		link: '/psc',
		iconHome: <SiGoogledomains size={80} />,
		iconMenu: <SiGoogledomains size={20} />,
	},
	{
		name: 'SEND EMAIL AS',
		admin: false,
		link: '/sendEmailAs',
		iconHome: <RiMailSendLine size={80} />,
		customClaim: true,
		iconMenu: <RiMailSendLine size={20} />,
	},
	{
		name: 'TICK LOGS',
		admin: false,
		link: '/tickLogs',
		iconHome: <BiSpreadsheet size={80} />,
		iconMenu: <BiSpreadsheet size={20} />,
	},
	{
		name: 'VACATION',
		admin: false,
		link: '/vacation',
		iconHome: <MdOutlineAirplanemodeActive size={80} />,
		iconMenu: <MdOutlineAirplanemodeActive size={20} />,
	},
	{
		name: 'VOTING',
		admin: false,
		link: '/voting',
		iconHome: <GiVote size={80} />,
		iconMenu: <GiVote size={20} />,
		memberType: 'Physician',
		isEPT: true,
	},
	{
		name: 'BULK UPDATE',
		admin: true,
		link: '/bulkupdate',
		iconHome: <BiCloudUpload size={40} />,
		iconMenu: <BiCloudUpload size={20} />,
	},
	{
		name: `DASHBOARD SETTINGS`,
		admin: true,
		link: '/dashboardsettings',
		iconHome: <AiOutlineDashboard size={40} />,
		iconMenu: <AiOutlineDashboard size={20} />,
	},
	{
		name: 'LOA LOG',
		admin: true,
		link: '/loaSabbatical',
		iconHome: <MdOutlineBeachAccess size={40} />,
		iconMenu: <MdOutlineBeachAccess size={20} />,
	},
	{
		name: 'PUBLIC DIRECTORY IDS',
		admin: true,
		link: '/publicIDs',
		iconHome: <GoFileDirectory size={40} />,
		iconMenu: <GoFileDirectory size={20} />,
	},
	{
		name: 'SETTINGS',
		admin: true,
		link: '/settings',
		iconHome: <BsGear size={40} />,
		iconMenu: <BsGear size={20} />,
	},
	{ name: 'UNIQUE IDS', admin: true, link: '/uniqueIDs', iconHome: <BsKey size={40} />, iconMenu: <BsKey size={20} /> },
	{
		name: 'USER VERIFICATION STATS',
		admin: true,
		link: '/userVerificationStats',
		iconHome: <BiStats size={40} />,
		iconMenu: <BiStats size={20} />,
	},
	{
		name: 'VACATION ADMIN',
		admin: true,
		link: '/vacationadmin',
		iconHome: <MdOutlineAirplanemodeActive size={40} />,
		iconMenu: <MdOutlineAirplanemodeActive size={20} />,
	},
];

function Home() {
	const authCtx = useContext(AuthContext);
	const claimsCtx = useContext(CustomClaimsContext);

	return (
		<Fragment>
			{/* if logged in */}
			{authCtx.isLoggedIn && (
				<Grid container spacing={1}>
					<Grid xs={12}>
						<PageCards>
							<Container>
								<Typography variant='h3' color='primary' m={2} fontWeight={400} textAlign={'center'}>
									EPT Portal
								</Typography>

								{/* portal icons */}
								<Grid container spacing={2} columns={4} mb={2} justifyContent='center'>
									{icons.map((icon, index) => {
										if (
											!icon.admin &&
											(!icon.memberType || claimsCtx.claims.memberType === icon.memberType) &&
											(icon.isEPT === undefined || claimsCtx.claims.isEPT === icon.isEPT)
										) {
											return (
												<Stack
													m={2}
													sx={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
													key={index}
												>
													<Button
														href={icon.link}
														variant='contained'
														size='large'
														color='button'
														sx={{
															'&:hover': {
																background: icon.hoverColor ? icon.hoverColor : '',
															},
															height: '120px',
															width: '150px',
															borderRadius: '8px',
															margin: 'auto',
															padding: '16px',
															background: icon.color ? icon.color : '',
														}}
													>
														<Stack>
															<Container>{icon.iconHome}</Container>
															<Typography variant='h7' fontWeight={400} textAlign='center' color='white'>
																{icon.name}
															</Typography>
														</Stack>
													</Button>
												</Stack>
											);
										}
									})}
								</Grid>
							</Container>

							{/* if admin */}
							{claimsCtx?.claims?.admin && (
								<Container>
									<Typography variant='h5' mt={5} fontWeight={400} textAlign='center'>
										Admin Tools
									</Typography>

									{/* admin tools are not available on portable device */}
									<Typography
										variant='caption'
										textAlign='center'
										sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
									>
										Admin Tools are not available on a smaller screen. Please login on a larger screen to use Admin
										Tools.
									</Typography>

									<Grid
										container
										spacing={2}
										columns={4}
										justifyContent='center'
										sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
									>
										{icons.map((icon, index) => {
											if (icon.admin) {
												return (
													<Stack
														m={2}
														sx={{
															display: 'flex',
															justifyContent: 'center',
														}}
														key={index}
													>
														<Button
															href={icon.link}
															color='button'
															variant='contained'
															size='large'
															sx={{
																height: '120px',
																width: '150px',
																borderRadius: '8px',
																margin: 'auto',
																padding: '8px',
															}}
														>
															<Stack>
																<Box sx={{ display: 'flex', justifyContent: 'center' }}>{icon.iconHome}</Box>
																<Typography variant='body' fontWeight={400} textAlign='center' color='white'>
																	{icon.name}
																</Typography>
															</Stack>
														</Button>
													</Stack>
												);
											}
										})}
									</Grid>
								</Container>
							)}
						</PageCards>
					</Grid>

					<br></br>
				</Grid>
			)}
		</Fragment>
	);
}

export default Home;
