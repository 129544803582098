import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { Fragment, useContext, useEffect, useState } from "react"
import TextEditor from "../textEditor/TextEditor";
import AlertContext from "../ui/AlertContext";
import SubmitPscData from "./SubmitPscData";
import { db } from "../../App";
import { doc, getDoc } from "firebase/firestore";

const CreateSensitiveForm = (props) => {
    const alertCtx = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [sensitiveContent, setSensitiveContent] = useState({});
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const getCategories = async() => {
			const categoryRef = doc(db, 'settings', 'PSCCategories');
			const temp_cat = (await getDoc(categoryRef)).data().SensitiveIssues;
			setCategories(temp_cat);
            if (temp_cat.length) setCategory(temp_cat[0]);
		}
		getCategories();
    },[])
    const handleSubmit = async () => {
        if (!sensitiveContent.valid) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Sensitive Issues content must be filled');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            return;
        }
        setIsLoading(true);
        const data = {
            Title: title,
            Details: sensitiveContent.text,
            Category: category,
            Visible: visible
        }
        const response = await SubmitPscData(data, 'Sensitive');
		if (response.data.code !== 200) {
            alertCtx.setSeverity('error');
			alertCtx.setTitle('Your Issue was <strong>not</strong> submitted. Try again.');
			alertCtx.setActive(true);
            alertCtx.setTimer(5000);
		} else {
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Issue was submitted successfully.');
            alertCtx.setActive(true);
			alertCtx.setTimer(5000);
		}
		setIsLoading(false);
    }
    const handleSensitiveChange = (e) => {
        setSensitiveContent(e);
    }
    const handleChange = (e, type) => {
        switch(type) {
            case 'Title':
                setTitle(e.target.value);
                break;
            case 'Category':
                setCategory(e.target.value);
                break;
            case 'Visible':
                setVisible(e.target.value);
                break;
            default:
                break;
        }
    }
    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <Box display="flex" justifyContent="space-between" gap={"10px"}>
                    <Box width="100%">
                        <FormControl fullWidth margin='dense'>
                            <InputLabel htmlFor="title">Title</InputLabel>
                            <Select
                                color='primary'
                                id="title"
                                label="Title"
                                value={title}
                                onChange={(e) => handleChange(e, 'Title')}
                                size="small"
                                fullWidth
                            >
                                <MenuItem value='Give Help'>
                                    Give Help
                                </MenuItem>
                                <MenuItem value='Get Help'>
                                    Get Help
                                </MenuItem>
                            </Select>
                        </FormControl>
                        {/* <TextField
                            id="sensitive_issue_title"
                            label="Title"
                            value={title}
                            onChange={(e) => handleChange(e, 'Title')}
                            size="small"
                            InputProps={{ sx: {marginBottom: '5px'}}}
                            fullWidth
                        /> */}
                    </Box>
                    <Box width="50%">
                        <FormControl fullWidth margin='dense'>
                            <InputLabel htmlFor="visible">Visible</InputLabel>
                            <Select
                                color='primary'
                                id="visible"
                                label="Visible"
                                value={visible}
                                onChange={(e) => handleChange(e, 'Visible')}
                                size="small"
                                fullWidth
                            >
                                <MenuItem value={true}>
                                    True
                                </MenuItem>
                                <MenuItem value={false}>
                                    False
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box width="50%">
                        <FormControl fullWidth margin='dense'>
                            <InputLabel htmlFor="sensitive_issue_category">Category</InputLabel>
                            <Select
                                color='primary'
                                id="sensitive_issue_category"
                                label="Cagetory"
                                value={category}
                                onChange={(e) => handleChange(e, 'Category')}
                                size="small"
                                fullWidth
                            >
                                {
                                    categories.map((item, index) => (
                                        <MenuItem key={index} value={item}>{item}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <TextEditor
                    initialContent={sensitiveContent.text}
                    onChange={handleSensitiveChange}
                />
            </Box>
            <Box display='flex' justifyContent='right'>
                {
                    isLoading ? (
                        <Button variant="contained" size="small" color="cancel" disabled={true}>
                            <CircularProgress color="inherit" size={22}/>
                        </Button>
                    ) : (
                        <Button
                            variant='contained'
                            color='button'
                            onClick={handleSubmit}
                            disabled={isLoading? true: false}
                        >
                            Submit
                        </Button>
                    )
                }
                
            </Box>
        </Fragment>
    );
}

export default CreateSensitiveForm