import { getFunctions, httpsCallable } from "firebase/functions"

const SubmitPscData = async (info, subcollectionName) => {
    return new Promise((resolve, reject) => {
        const functions = getFunctions();
        const data = {
            data: info,
            subcollectionName: subcollectionName
        }
        const pscUserSubmissions = httpsCallable(functions, 'pscUserSubmissions');
        pscUserSubmissions(data).then((res) => {
            resolve(res);
        }).catch((err) => {
            reject(err);
        })
    })
}

export default SubmitPscData;