import { Fragment, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import TextEditor from "../textEditor/TextEditor";
import AlertContext from "../ui/AlertContext";
import isExternalLink from "../localFunctions/isExternalLink";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../App";

const CreateCommensality = (props) => {
    const alertCtx = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [elink, setElink] = useState('');
    const [elinkError, setElinkError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [visible, setVisible] = useState(false);
    const [resourceContent, setResourceContent] = useState({});

    const formatForm = () => {
        setTitle('');
        setTitleError(false);
        setElink('');
        setElinkError(false);
        setEmail('');
        setEmailError(true);
        setResourceContent({});
    }

    const handleSubmit = () => {
        if (!title){
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Title must be filled');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            return;
        }
        const data = {
            GoogleGroupName: title,
            Blurb: resourceContent.text,
            Link: elink,
            Email: email,
            Visible: visible,
            SubscribeUsers: []
        }
        const commensalityRef = collection(db, 'PSC', 'Commensality', 'Commensality');
        try {
            const docRef = addDoc(commensalityRef, data);
            props.handleAddItem({
                id: docRef.id,
                ...data
            });
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Commensality was submitted successfully.');
            alertCtx.setActive(true);
			alertCtx.setTimer(5000);
            formatForm();
            setIsLoading(false);
        } catch(error) {
            alertCtx.setSeverity('error');
            alertCtx.setTitle('Commensality was <strong>not</strong> submitted. Try again.');
            alertCtx.setActive(true);
            alertCtx.setTimer(5000);
            setIsLoading(false);
        }
    }
    const handleChange = (e, type) => {
        switch(type) {
            case 'Title':
                setTitle(e.target.value);
                if (!e.target.value) setTitleError(true);
                else setTitleError(false);
                break;
            case 'Email':
                setEmail(e.target.value);
                if (!e.target.value) setEmailError(true);
                else setEmailError(false);
                break;
            case 'Elink':
                setElink(e.target.value);
                if (e.target.value && !isExternalLink(e.target.value)) setElinkError(true);
                else setElinkError(false);
                break;
            case 'Visible':
                setVisible(e.target.value);
                break;
            default:
                break;
        }
    }
    const handleResourceChange = (e) => {
        setResourceContent(e);
    }
    return (
        <Fragment>
            <Box display='flex' justifyContent='space-between' gap='5px'>
                <Box width="50%">
                    <TextField
                        label="Google Group Name"
                        value={title}
                        onChange={(e) => handleChange(e, 'Title')}
                        size="small"
                        InputProps={{ sx: {marginBottom: '5px'}}}
                        error={titleError}
                        helperText={titleError? 'Empty google group name': ''}
                        fullWidth
                    />
                </Box>
                <Box width="50%">
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel htmlFor="visible">Visible</InputLabel>
                        <Select
                            color='primary'
                            id="visible"
                            label="Visible"
                            value={visible}
                            onChange={(e) => handleChange(e, 'Visible')}
                            size="small"
                            fullWidth
                        >
                            <MenuItem value={true}>
                                True
                            </MenuItem>
                            <MenuItem value={false}>
                                False
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box width='50%'>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => handleChange(e, 'Email')}
                        size="small"
                        InputProps={{ sx: {marginBottom: '5px'}}}
                        error={emailError}
                        helperText={emailError? 'Empty email': ''}
                        fullWidth
                    />
                </Box>
                <Box width='50%'>
                    <TextField
                        label='Link'
                        variant='outlined'
                        value={elink}
                        onChange={(e) => handleChange(e, 'Elink')}
                        size="small"
                        error={elinkError}
                        helperText={elinkError? "Missing a 'https' or 'http' prefix.": ''}
                        fullWidth
                    />
                </Box>
            </Box>
            <Box sx={{width: '100%'}} marginTop='5px'>
                <TextEditor
                    initialContent={resourceContent.text}
                    onChange={handleResourceChange}
                />
            </Box>
            <Box display='flex' justifyContent='right'>
                <Button
                    variant='contained'
                    color='button'
                    onClick={handleSubmit}
                    disabled={isLoading? true: false}
                >
                    Submit
                </Button>
            </Box>
        </Fragment>
    )
}

export default CreateCommensality;