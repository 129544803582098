import { Fragment, useEffect, useMemo, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import CircularProgress from '@mui/material/CircularProgress';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import { IoIosCloseCircleOutline } from 'react-icons/io';
import CloseSvg from '../images/close.svg';
import UploadIcon from '../images/upload-icon.svg';
import { TbEdit } from 'react-icons/tb';
import { MdDeleteOutline } from 'react-icons/md';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import TextEditor from '../textEditor/TextEditor';
import AlertContext from '../ui/AlertContext';
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../App';
import { getDownloadURL, ref, updateMetadata, uploadBytes } from 'firebase/storage';
import { Link } from 'react-router-dom';
import UpdatePscData from './UpdatePscData';
import styled from '@emotion/styled';

const StyledBlurb = styled(Box)(({ theme }) => ({
	maxWidth: '300px',
	textAlign: 'left',
	overflow: 'hidden',
	display: '-webkit-box',
	WebkitLineClamp: '3',
	WebkitBoxOrient: 'vertical',
	wordWrap: 'break-word',
	textWrap: 'wrap',
}));

const AttachmentView = styled(Box)(({ theme }) => ({
	width: '200px',
	maxHeight: '180px',
	minHeight: '125px',
	height: '100%',
	overflow: 'hidden',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: '#d9d9d9',
	position: 'relative',
	'&:hover': {
		background: '#c1bfbf',
	},
}));

const AngieListTable = (props) => {
	const alertCtx = useContext(AlertContext);
	const [rows, setRows] = useState([]);
	const [modalObj, setModalObj] = useState({});
	const [reviewObj, setReviewObj] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [reviewOpen, setReviewOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [deleteRowId, setDeleteRowId] = useState(null);
	const [reviewError, setReviewError] = useState(false);
	const [categories, setCategories] = useState([]);
	const [reviewLoading, setReviewLoading] = useState(false);
	const [editLoading, setEditLoading] = useState(false);
	const [imagePreview, setImagePreview] = useState(null);
	const [selectedImage, setSelectedImage] = useState(null);
	const [imageOpen, setImageOpen] = useState(false);
	const [selectedImageUrl, setSelectedImageUrl] = useState('');

	useEffect(() => {
		const getCategories = async () => {
			const categoryRef = doc(db, 'settings', 'PSCCategories');
			const temp_cat = (await getDoc(categoryRef)).data().AngiesList;
			setCategories(temp_cat);
		};
		getCategories();
	}, []);

	useEffect(() => {
		setRows(props.list);
	}, [props.list]);

	useEffect(() => {
		if (selectedImage) {
			setImagePreview(URL.createObjectURL(selectedImage.file));
		} else {
			setImagePreview(null);
		}
	}, [selectedImage]);

	const alert = (type, msg, duration = 5000) => {
		alertCtx.setSeverity(type);
		alertCtx.setTitle(msg);
		alertCtx.setActive(true);
		alertCtx.setTimer(duration);
	};
	const handleEditClick = (row) => {
		setModalObj(row);
		setModalOpen(true);
	};
	const handleDeleteClick = (row) => {
		setDeleteRowId(row.id);
		setConfirmOpen(true);
	};
	const handleClose = () => {
		setModalOpen(false);
		setConfirmOpen(false);
		setReviewOpen(false);
		setSelectedImage('');
		setImageOpen(false);
	};
	const handleImageClick = (url) => {
		setSelectedImageUrl(url);
		setImageOpen(true);
	};
	const handleChange = (e, type) => {
		setModalObj({
			...modalObj,
			[type]: e.target.value,
		});
	};
	const handleResourceChange = (e) => {
		setModalObj({
			...modalObj,
			blurb: e.text,
		});
	};
	const updateResourceList = (one, type = 'update') => {
		if (type === 'update') {
			const newRows = rows.map((item) => {
				if (item.id === one.id) return one;
				else return item;
			});
			setRows(newRows);
		} else {
			const newRows = rows.filter((item) => item.id !== one);
			setRows(newRows);
		}
	};
	const handleSaveResource = () => {
		const idx = modalObj.id;
		if (!idx) {
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Select item to update, again');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
			return;
		}
		const angieRef = doc(db, 'PSC', 'AngiesList', 'AngiesList', idx);
		const tempObj = JSON.parse(JSON.stringify(modalObj));
		delete tempObj['id'];
		setEditLoading(true);
		updateDoc(angieRef, tempObj)
			.then(() => {
				setEditLoading(false);
				setModalOpen(false);
				updateResourceList(modalObj, 'update');
				alertCtx.setSeverity('success');
				alertCtx.setTitle('Selected row successfully updated!');
				alertCtx.setActive(true);
				alertCtx.setTimer(5000);
			})
			.catch((error) => {
				setEditLoading(false);
				setModalOpen(false);
				alertCtx.setSeverity('error');
				alertCtx.setTitle(error.message);
				alertCtx.setActive(true);
				alertCtx.setTimer(5000);
			});
	};
	const handleDeleteAction = async () => {
		if (!deleteRowId) {
			handleClose();
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Select item to delete, again');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
			return;
		}
		const resourceRef = doc(db, 'PSC', 'AngiesList', 'AngiesList', deleteRowId);
		try {
			await deleteDoc(resourceRef);
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Item successfully deleted!');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
			updateResourceList(deleteRowId, 'delete');
			handleClose();
		} catch (error) {
			alertCtx.setSeverity('error');
			alertCtx.setTitle(error.message);
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
			handleClose();
		}
	};
	const handleReviewChange = (e, type) => {
		setReviewObj({
			...reviewObj,
			[type]: e,
		});
		if (type === 'Review' && !e) setReviewError(true);
		else setReviewError(false);
	};
	const handleReviewOpen = (row) => {
		setModalObj(row);
		setReviewObj({
			Review: '',
			Comments: '',
			Rating: 0,
			ByName: props.name,
			ByEmail: props.email,
		});
		setReviewOpen(true);
	};
	const handleAddReview = async () => {
		if (!reviewObj.Review) {
			setReviewError(true);
			return;
		}
		setReviewLoading(true);
		reviewObj.Date = new Date().toLocaleDateString('en-CA', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		});
		const tempObj = JSON.parse(JSON.stringify(modalObj));
		if (!tempObj.Reviews) tempObj.Reviews = [];
		if (selectedImage) {
			const downloadUrl = await handleUploadProcess();
			if (downloadUrl === null) {
				return;
			}
			reviewObj.attachment = downloadUrl;
		}

		tempObj.Reviews.push(reviewObj);

		const response = await UpdatePscData(tempObj, 'AngiesList');
		if (response.data.code !== 200) {
			setReviewLoading(false);
			setReviewOpen(false);
			alertCtx.setSeverity('error');
			alertCtx.setTitle(response.data.message);
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
		} else {
			setReviewLoading(false);
			setReviewOpen(false);
			updateResourceList(tempObj, 'update');
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Selected row successfully updated!');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
		}
	};

	// handle attachment
	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		if (file) {
			// Check if the file type is an image
			if (!file.type.startsWith('image/')) {
				alert('error', 'Please select an image file.');
				return;
			}

			// Check if the file size is more than 50MB (50 * 1024 * 1024 bytes)
			if (file.size > 50 * 1024 * 1024) {
				alert('error', 'File size must not exceed 50MB.');
				return;
			}

			// Create the unique file name
			const uniqueFileName = `${Date.now()}_${file.name}`;

			// Set the selected image with its unique name
			setSelectedImage({
				file: file,
				name: uniqueFileName,
			});
			console.log(uniqueFileName);
		}
	};
	const handleUploadClick = () => {
		document.getElementById('angie-file-input').click();
	};
	const handleRemoveFile = () => {
		setSelectedImage(null);
	};
	const handleUploadProcess = async () => {
		const storageRef = ref(storage, `PSC/Events/${selectedImage.name}`);
		const snapshot = await uploadBytes(storageRef, selectedImage.file);
		const metadata = {
			customMetadata: {
				userName: props.name,
			},
		};

		try {
			await updateMetadata(storageRef, metadata);
			const downloadURL = await getDownloadURL(snapshot.ref);
			return downloadURL;
		} catch (error) {
			alert('error', error.message);
			return null;
		}
	};
	const stripHtml = (html) => {
		const tempororyDiv = document.createElement('div');
		tempororyDiv.innerHTML = html;

		const text = tempororyDiv.textContent || tempororyDiv.innerText || '';

		return text.slice(0, 200);
	};

	const columnsAngiesList = useMemo(() => {
		return [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				id: 'SubmitterName',
				accessorFn: (row) => (row.SubmitterName ? `${row.SubmitterName}` : ''),
				header: 'Submitter name',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.SubmitterName}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'BusinessName',
				accessorFn: (row) => (row.BusinessName ? row.BusinessName : ''),
				header: 'Business name',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.BusinessName}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'BusinessPhone',
				accessorFn: (row) => (row.BusinessPhone ? row.BusinessPhone : ''),
				header: 'Business phone',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<Typography variant='p'>{row.original.BusinessPhone}</Typography>
						</Stack>
					</Box>
				),
			},
			{
				id: 'BusinessEmail',
				accessorFn: (row) => (row.BusinessEmail ? row.BusinessEmail : ''),
				header: 'Business email',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.BusinessEmail}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'BusinessAddress',
				accessorFn: (row) => (row.BusinessAddress ? row.BusinessAddress : ''),
				header: 'Business address',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.BusinessAddress}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'blurb',
				accessorFn: (row) => (row.blurb ? row.blurb : ''),
				header: 'Blurb',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<div className='no-margin-p' dangerouslySetInnerHTML={{ __html: row.original.blurb }}></div>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Category',
				accessorFn: (row) => (row.Category ? row.Category : ''),
				header: 'Category',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Category}</p>
						</Stack>
					</Box>
				),
			},
		];
	}, []);
	const columnsPscAngiesList = useMemo(() => {
		return [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				id: 'SubmitterName',
				accessorFn: (row) => (row.SubmitterName ? `${row.SubmitterName}` : ''),
				header: 'Submitter name',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.SubmitterName}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'BusinessName',
				accessorFn: (row) => (row.BusinessName ? row.BusinessName : ''),
				header: 'Business name',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.BusinessName}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'BusinessPhone',
				accessorFn: (row) => (row.BusinessPhone ? row.BusinessPhone : ''),
				header: 'Business phone',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<Typography variant='p'>{row.original.BusinessPhone}</Typography>
						</Stack>
					</Box>
				),
			},
			{
				id: 'BusinessEmail',
				accessorFn: (row) => (row.BusinessEmail ? row.BusinessEmail : ''),
				header: 'Business email',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.BusinessEmail}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'BusinessAddress',
				accessorFn: (row) => (row.BusinessAddress ? row.BusinessAddress : ''),
				header: 'Business address',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.BusinessAddress}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'blurb',
				accessorFn: (row) => (row.blurb ? row.blurb : ''),
				header: 'Blurb',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<StyledBlurb className='no-margin-p-left'>{stripHtml(row.original.blurb)}</StyledBlurb>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Category',
				accessorFn: (row) => (row.Category ? row.Category : ''),
				header: 'Category',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<p>{row.original.Category}</p>
						</Stack>
					</Box>
				),
			},
			{
				id: 'Visible',
				accessorFn: (row) => (row.Visible ? row.Visible : ''),
				header: 'Visible',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center'>
						<Stack direction='row' spacing={1}>
							<Typography
								variant='p'
								style={{
									color: '#444',
								}}
							>
								{row.original.Visible ? 'True' : 'False'}
							</Typography>
						</Stack>
					</Box>
				),
			},
			{
				id: 'action',
				header: 'Action',
				muiTableHeadCellProps: {
					align: 'center',
				},
				Cell: ({ row }) => (
					<Box display='flex' justifyContent='center' gap='5px'>
						<IconButton onClick={() => handleEditClick(row.original)}>
							<TbEdit></TbEdit>
						</IconButton>
						<IconButton onClick={() => handleDeleteClick(row.original)}>
							<MdDeleteOutline></MdDeleteOutline>
						</IconButton>
					</Box>
				),
			},
		];
	}, []);
	const table = useMaterialReactTable({
		columns: props.pscMember ? columnsPscAngiesList : columnsAngiesList,
		data: rows,
		muiTablePaperProps: {
			elevation: 0,
			sx: {
				'& tr:nth-of-type(even)': {
					backgroundColor: '#f5f5f5',
				},
			},
		},
		displayColumnDefOptions: {
			'mrt-row-actions': {
				muiTableHeadCellProps: {
					align: 'center',
				},
				size: 120,
			},
		},
		initialState: {
			density: 'compact',
			expanded: false,
			pagination: { pageIndex: 0, pageSize: 15 },
			sorting: [{ id: 'SubmitterName', desc: false }],
			columnVisibility: {
				id: false,
			},
		},
		renderDetailPanel: ({ row }) => {
			if (!row.original.Reviews || !row.original.Reviews?.length) {
				return (
					<Box maxWidth='calc(100vw - 6rem)' position='sticky' left='20px'>
						<Box display='flex' justifyContent='center'>
							<Stack direction='row' spacing={1}>
								<p>There is no reviews</p>
							</Stack>
						</Box>
						<Box display='flex' justifyContent='right'>
							<Stack direction='row' spacing={1}>
								<Button
									onClick={() => handleReviewOpen(row.original)}
									color='success'
									sx={{
										color: 'white',
									}}
									variant='contained'
								>
									Leave Review
								</Button>
							</Stack>
						</Box>
					</Box>
				);
			} else {
				return (
					<Box
						sx={{
							maxWidth: {
								xs: '600px',
								sm: 'calc(100vw - 6rem)',
							},
							position: {
								xs: 'static',
								sm: 'sticky',
							},
							left: '20px',
							marginBottom: '1rem',
						}}
					>
						{row.original.Reviews.map((item, index) => (
							<Box key={index} display='flex' justifyContent='space-between' gap='20px' marginBottom={2}>
								<Box width='100%'>
									<Box display='flex' justifyContent='left' gap='5px'>
										<Rating
											name={`rating-${index}`}
											value={item.Rating}
											precision={0.5}
											sx={{
												color: '#1F2253',
											}}
											readOnly
										/>
										<Typography variant='h6'>{item.Review}</Typography>
									</Box>
									<Box>
										<p>{item.Comments}</p>
									</Box>
								</Box>
								{item.attachment && (
									<AttachmentView minHeight='100px'>
										<img
											src={item.attachment}
											alt='Review attachment'
											style={{ width: '100%', height: 'auto', filter: 'brightness(0.9)', cursor: 'pointer' }}
											onClick={() => handleImageClick(item.attachment)}
										/>
									</AttachmentView>
								)}
								<Box width='50%'>
									<p>
										Date:&nbsp;&nbsp; <span style={{ fontWeight: '500' }}>{item.Date}</span>
									</p>
									<p>
										Name:{' '}
										<Link
											to='#'
											style={{
												textDecoration: 'none',
												color: 'black',
												fontWeight: '500',
											}}
										>
											{item.ByName}
										</Link>
									</p>
								</Box>
							</Box>
						))}
						<Box display='flex' justifyContent='right'>
							<Stack direction='row' spacing={1}>
								<Button
									onClick={() => handleReviewOpen(row.original)}
									color='success'
									sx={{
										color: 'white',
									}}
									variant='contained'
								>
									Leave Review
								</Button>
							</Stack>
						</Box>
					</Box>
				);
			}
		},
	});
	return (
		<Fragment>
			<Box xs={{ width: '100%' }} id='angielisttable'>
				<MaterialReactTable table={table}></MaterialReactTable>
				<Dialog open={modalOpen} onClose={handleClose} maxWidth='xl'>
					<Box sx={{ padding: '5px' }}>
						<DialogTitle>Edit Blurb of Resource</DialogTitle>
						<DialogContent>
							<Box mt={1} display='flex' justifyContent='space-between' gap='5px'>
								{/* display submitter name */}
								<TextField
									fullWidth
									size='small'
									margin='dense'
									value={modalObj.SubmitterName}
									disabled={true}
									label='Submitter Name'
									hiddenLabel
									variant='outlined'
									color='secondary'
								/>
								<TextField
									fullWidth
									size='small'
									margin='dense'
									value={modalObj.BusinessEmail}
									disabled={true}
									label='Business Email'
									hiddenLabel
									variant='outlined'
									color='secondary'
								/>
								<FormControl fullWidth margin='dense'>
									<InputLabel id={`Category`}>Category</InputLabel>
									<Select
										id='CustomCategory'
										margin='dense'
										color='secondary'
										labelId='Category'
										label='Category'
										size='small'
										value={modalObj.Category}
										onChange={(e) => {
											handleChange(e, 'Category');
										}}
									>
										{categories.map((item, index) => (
											<MenuItem key={index} value={item}>
												{item}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							<Box mt={1} display='flex' justifyContent='space-between' gap='5px'>
								<TextField
									label='Business name'
									variant='outlined'
									value={modalObj.BusinessName ? modalObj.BusinessName : ''}
									onChange={(e) => handleChange(e, 'BusinessName')}
									size='small'
									fullWidth
								/>
								<TextField
									label='Business address'
									variant='outlined'
									value={modalObj.BusinessAddress ? modalObj.BusinessAddress : ''}
									onChange={(e) => handleChange(e, 'BusinessAddress')}
									size='small'
									fullWidth
								/>
								<TextField
									label='Business phone'
									variant='outlined'
									value={modalObj.BusinessPhone ? modalObj.BusinessPhone : ''}
									onChange={(e) => handleChange(e, 'BusinessPhone')}
									size='small'
									fullWidth
								/>
							</Box>
							<Box display='flex' justifyContent='flex-end' gap='5px'>
								<FormControl fullWidth margin='dense' sx={{ maxWidth: '33%' }}>
									<InputLabel id={`Visible`}>Visible</InputLabel>
									<Select
										id='CustomVisible'
										margin='dense'
										color='secondary'
										labelId='Visible'
										label='Visible'
										size='small'
										value={modalObj.Visible ? true : false}
										onChange={(e) => {
											handleChange(e, 'Visible');
										}}
									>
										<MenuItem value={true}>True</MenuItem>
										<MenuItem value={false}>False</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<Box>
								<TextEditor initialContent={modalObj.blurb} onChange={handleResourceChange} />
							</Box>
						</DialogContent>
						<DialogActions>
							<Button variant='contained' onClick={handleClose} color='cancel'>
								Cancel
							</Button>
							{editLoading ? (
								<Button variant='contained' size='small' color='cancel' disabled={true}>
									<CircularProgress color='inherit' size={22} />
								</Button>
							) : (
								<Button variant='contained' onClick={handleSaveResource}>
									Save
								</Button>
							)}
						</DialogActions>
					</Box>
				</Dialog>
				<Dialog open={reviewOpen} onClose={handleClose} maxWidth='lg'>
					<Box sx={{ padding: '5px' }}>
						<DialogTitle>Leave Review</DialogTitle>
						<DialogContent>
							<Box>
								<p style={{ marginBottom: '5px', marginTop: '0px' }}>
									Review <span style={{ color: 'red' }}>*</span>
								</p>
								<TextField
									id='review'
									multiline
									fullWidth
									rows={2}
									value={reviewObj.Review}
									error={reviewError}
									onChange={(e) => handleReviewChange(e.target.value, 'Review')}
								/>
							</Box>
							<Box width='100%' textAlign='left' marginTop='15px'>
								<p style={{ marginBottom: '5px' }}>
									Quality of resource <span style={{ color: 'red' }}>*</span>
								</p>
								<Rating
									name='rating'
									value={reviewObj.Rating}
									onChange={(event, newValue) => {
										handleReviewChange(newValue, 'Rating');
									}}
									precision={0.5}
									sx={{
										color: '#1F2253',
									}}
								/>
							</Box>
							<Box>
								<p style={{ marginBottom: '5px', marginTop: '10px' }}>Comment</p>
								<TextField
									id='comments'
									multiline
									fullWidth
									rows={5}
									value={reviewObj.Comments}
									onChange={(e) => handleReviewChange(e.target.value, 'Comments')}
								/>
							</Box>
							<Box marginTop='15px'>
								<Typography variant='p' style={{ marginBottom: '5px', marginTop: '10px' }}>
									Attachment
								</Typography>
								<AttachmentView>
									{selectedImage ? (
										<>
											<img
												src={imagePreview}
												alt='Angie Preview'
												style={{ width: '100%', height: 'auto', filter: 'brightness(0.9)' }}
												onClick={() => handleUploadClick()}
											/>
											<Box
												sx={{
													position: 'absolute',
													right: '5px',
													top: '5px',
													cursor: 'pointer',
													fontSize: '1.5rem',
													'&:hover': { color: 'red' },
												}}
											>
												<IoIosCloseCircleOutline onClick={() => handleRemoveFile()}></IoIosCloseCircleOutline>
											</Box>
										</>
									) : (
										<img
											src={UploadIcon}
											alt='Upload'
											style={{ width: '100px', margin: 'auto', marginTop: '9px', cursor: 'pointer' }}
											onClick={() => handleUploadClick()}
										/>
									)}
								</AttachmentView>
							</Box>
							<input
								id='angie-file-input'
								style={{ display: 'none' }}
								type='file'
								onChange={handleFileUpload}
								accept='image/*'
							/>
						</DialogContent>
						<DialogActions sx={{ paddingRight: '24px' }}>
							<Button variant='contained' onClick={handleClose} color='cancel'>
								Cancel
							</Button>
							{reviewLoading ? (
								<Button variant='contained' size='small' color='cancel' disabled={true}>
									<CircularProgress color='inherit' size={22} />
								</Button>
							) : (
								<Button variant='contained' onClick={handleAddReview}>
									Submit
								</Button>
							)}
						</DialogActions>
					</Box>
				</Dialog>
				<DeleteConfirmDialog open={confirmOpen} handleClose={handleClose} handleDeleteAction={handleDeleteAction} />
				<Dialog open={imageOpen} onClose={handleClose} maxWidth='xl'>
					<DialogContent style={{ overflow: 'hidden', padding: '0px', position: 'relative' }}>
						<img
							src={CloseSvg}
							alt='close'
							onClick={handleClose}
							style={{
								position: 'absolute',
								width: '35px',
								height: '35px',
								top: '0px',
								right: '0px',
								cursor: 'pointer',
							}}
						/>
						<img src={selectedImageUrl} alt={'loading...'} style={{ height: '100vh' }} />
					</DialogContent>
				</Dialog>
			</Box>
		</Fragment>
	);
};

export default AngieListTable;
