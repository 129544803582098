import { useEffect, useState, useContext } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import PageCards from "../../components/ui/PageCards";
import Typography from '@mui/material/Typography';
import CheckToken from "../../components/auth/CheckToken";
import UpcommingEventTable from "../../components/pscinfo/UpcommingEventTable";
import PastEventTable from "../../components/pscinfo/PastEventTable";
import CustomClaimsContext from "../../components/auth/CustomClaimsContext";

import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../App";
import CreateEventForm from "../../components/pscinfo/CreateEventForm";

const EptWideEvents = () => {
    const claimsCtx = useContext(CustomClaimsContext);
    const [pastEventList, setPastEventList] = useState([]);
    const [upcommingEventList, setUpcommingEventList] = useState([]);
    useEffect(() => {
        const getEventList = async() => {
            const tmpPastList = [];
            const tmpUpcommingList = [];
            const eventRef = collection(db, 'PSC', 'Events', 'Events');
            let querySnapshot;
            if (claimsCtx?.claims?.pscMember) { querySnapshot = await getDocs(eventRef); }
            else { querySnapshot = await getDocs(query(eventRef, where("Visible", "==", true))); }
            querySnapshot.forEach((item) => {
                const data = item.data();
                if ( data.Date?.seconds ) {
                    if (data.Date.seconds * 1000 <= Date.now()) tmpPastList.push({id: item.id, ...data});
                    else tmpUpcommingList.push({id: item.id, ...data});
                } else {
                    if(new Date(data.Date).getTime() <= new Date().getTime()) tmpPastList.push({id: item.id, ...data});
                    else tmpUpcommingList.push({id: item.id, ...data});
                }
            })
            setPastEventList(tmpPastList);
            setUpcommingEventList(tmpUpcommingList);
        }
        getEventList();
    },[claimsCtx?.claims?.pscMember])

    const handleAddItem = (item) => {
        setUpcommingEventList([...upcommingEventList, item]);
    }
    return (
        <>
            <Grid xs={12}>
                <PageCards>
                    <Typography variant="h3" color="primary" textAlign={"center"}>
                        EPT Wide Events
                    </Typography>
                </PageCards>
            </Grid>
            <Grid xs={12}>
                <PageCards>
                    <CheckToken>
                        <UpcommingEventTable
                            pscMember={claimsCtx?.claims?.pscMember}
                            list={upcommingEventList}
                        />
                    </CheckToken>
                </PageCards>
            </Grid>
            <Grid xs={12}>
                <PageCards>
                    <CheckToken>
                        <PastEventTable
                            pscMember={claimsCtx?.claims?.pscMember}
                            list={pastEventList}
                            email={claimsCtx.claims.email}
                            name={claimsCtx.claims.name}
                        />
                    </CheckToken>
                </PageCards>
            </Grid>
            {
                claimsCtx?.claims?.pscMember && (
                    <Grid xs={12}>
                        <PageCards>
                            <CheckToken>
                                <CreateEventForm handleAddItem={handleAddItem} />
                            </CheckToken>
                        </PageCards>
                    </Grid>
                )
            }
        </>
    )
}

export default EptWideEvents;