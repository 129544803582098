import { API_KEY } from '../auth/SignIn';
import { gapi } from 'gapi-script';

/**
 *
 * @param {*} token - the accessToken for the user
 * @returns - the photoURL of the email address provided
 */
export default function GetAllPeopleList(token) {
	return new Promise((succeeded) => {
		const initClient = async () => {
			gapi.client.setToken({ access_token: token });
			try {
				let pageToken = null;
				const newArray = [];
				do {
					await gapi.client
						.request({
							method: 'GET',
							path:
								pageToken !== null
									? `https://people.googleapis.com/v1/people:listDirectoryPeople?pageSize=100&pageToken=${pageToken}&readMask=names%2CemailAddresses&sources=DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE&key=${API_KEY}`
									: `https://people.googleapis.com/v1/people:listDirectoryPeople?pageSize=100&readMask=names%2CemailAddresses&sources=DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE&key=${API_KEY}`,
							headers: {
								Authorization: `Bearer ${token}`,
							},
						})
						.then((response) => {
							if (response) {
								if (response.result.nextPageToken) {
									pageToken = response.result.nextPageToken;
								} else {
									pageToken = null;
								}
								console.log(response);
								response.result.people.forEach((person) => {
									if (person.emailAddresses !== undefined) {
										newArray.push({
											email: person.emailAddresses[0]['value'],
											label: person.names[0].displayName,
										});
									}
								});
							}
						});
				} while (pageToken !== null);
				const sortArray = newArray.sort((a, b) =>
					a.label.toLowerCase() > b.label.toLowerCase() ? 1 : b.label.toLowerCase() > a.label.toLowerCase() ? -1 : 0
				);
				succeeded(sortArray);
			} catch (err) {
				console.log('Error: ' + err.result.error.message);
				succeeded({ title: 'Error', message: err.result.error.message });
			}
		};
		gapi.load('client', initClient);
	});
}
